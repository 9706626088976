<template>
  <div class="full-loading-wrapper">
    <div class="full-loading">
      <img src="./loading.gif" />
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import {getTranslateText} from '../../common/js/util';

export default {
  data() {
    return {
      showFlag: false
    }
  },
  props: {
    title: {
      type: String,
      default: '正在载入'
    }
  },
  methods: {
    show() {
      this.showFlag = true;
    },
    hide() {
      this.showFlag = false;
    },
    getTitle(text) {
      return getTranslateText(text);
    }
  }
};
</script>
<style lang="scss" scoped>
.full-loading-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  /*background-color: rgba(0, 0, 0, 0.1);*/
  img {
    z-index: 999999;
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
  }
}
</style>
