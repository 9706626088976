import * as types from "./types";
import {
  detailCurePlan,
  casesDetail,
  caseDiagnosisDetail,
  PublicProductTypeListByDoctor,
  optionalListByProduct,
  PublicCaseListAdditional,
  PublicCaseProgressListAdditional
} from "common/api/cases";
import { each } from "lodash";

export default {
  //治疗表详情
  actionGetCaseCureDetail({ state, commit }, query) {
    if (state.commonCaseCureDetail.state === "loading") {
      return Promise.reject();
    }
    commit(types.ACTION_GET_CASE_CURE_DETAIL, {
      state: "loading"
    });
    return detailCurePlan({ id: query.id, token: query.token })
      .then(result => {
        commit(types.ACTION_GET_CASE_CURE_DETAIL, {
          detail: result,
          state: "loadend"
        });
        return result;
      })
      .catch(err => {
        commit(types.ACTION_GET_CASE_CURE_DETAIL, {
          state: "loaderr"
        });
        return Promise.reject(err);
      });
  },
  //病例详情
  actionGetCommonCaseDetail({ state, commit }, query) {
    if (state.commonCaseDetail.state === "loading") {
      return Promise.reject();
    }
    commit(types.ACTION_GET_COMMON_CASE_DETAIL, {
      state: "loading"
    });
    return casesDetail(query)
      .then(result => {
        console.log("result", result);
        commit(types.ACTION_GET_COMMON_CASE_DETAIL, {
          detail: result,
          state: "loadend"
        });
        return result;
      })
      .catch(err => {
        commit(types.ACTION_GET_COMMON_CASE_DETAIL, {
          state: "loaderr"
        });
        return Promise.reject(err);
      });
  },
  //治疗表 详情
  actionGetCommonDiagnosisDetail({ state, commit }, query) {
    if (state.commonDiagnosisDetail.state === "loading") {
      return Promise.reject();
    }
    commit(types.ACTION_GET_DIAGNOS_DETAIL, {
      state: "loading"
    });
    return caseDiagnosisDetail(query)
      .then(result => {
        commit(types.ACTION_GET_DIAGNOS_DETAIL, {
          detail: result,
          state: "loadend"
        });
        return result;
      })
      .catch(err => {
        commit(types.ACTION_GET_DIAGNOS_DETAIL, {
          state: "loaderr"
        });
        return Promise.reject(err);
      });
  },

  //追加产品的列表
  actionGetCommonAddProductList({ state, commit }, query) {
    if (state.commonAddProductList.state === "loading") {
      return Promise.reject();
    }
    commit(types.ACTION_GET_ADD_PRODUCT_List, {
      state: "loading"
    });
    return optionalListByProduct(query)
      .then(result => {
        let list = [];
        each(result, it => list.push(it));
        commit(types.ACTION_GET_ADD_PRODUCT_List, {
          list: list,
          state: "loadend"
        });
        return result;
      })
      .catch(err => {
        commit(types.ACTION_GET_DIAGNOS_DETAIL, {
          state: "loaderr"
        });
        return Promise.reject(err);
      });
  },
  //历史列表
  actionGetAddHistoryList({ state, commit }, query) {
    if (state.addHistoryList.state === "loading") {
      return Promise.reject();
    }
    commit(types.ACTION_GET_ADD_HISTROY_List, {
      state: "loading"
    });
    return PublicCaseListAdditional(query)
      .then(result => {
        commit(types.ACTION_GET_ADD_HISTROY_List, {
          list: result,
          state: "loadend"
        });
        return result;
      })
      .catch(err => {
        commit(types.ACTION_GET_ADD_HISTROY_List, {
          state: "loaderr"
        });
        return Promise.reject(err);
      });
  },
  //追加进度列表
  actionGetCaseProgressList({ state, commit }, query) {
    if (state.caseProgressList.state === "loading") {
      return Promise.reject();
    }
    commit(types.ACTION_GET_CASE_PROGRESS_LIST, {
      state: "loading"
    });
    return PublicCaseProgressListAdditional(query)
      .then(result => {
        commit(types.ACTION_GET_CASE_PROGRESS_LIST, {
          list: result,
          state: "loadend"
        });
        return result;
      })
      .catch(err => {
        commit(types.ACTION_GET_CASE_PROGRESS_LIST, {
          state: "loaderr"
        });
        return Promise.reject(err);
      });
  }
};
