import actions from "./actions";
import * as types from "./types";
import { extend } from "lodash";

const state = {
  //治疗表详情
  commonCaseCureDetail: {
    state: "",
    detail: {}
  },
  //病例详情
  commonCaseDetail: {
    state: "",
    detail: {}
  },
  commonDiagnosisDetail: {
    state: "",
    detail: {}
  },
  // 追加产品列表
  commonAddProductList: {
    list: [],
    state: false
  },
  // 追加历史列表
  addHistoryList: {
    list: [],
    state: false
  },
  // 追加进度列表
  caseProgressList: {
    list: [],
    state: false
  }
};

const getters = {
  getCommonCaseCureDetail: state => state.commonCaseCureDetail, //治疗表详情
  getCommonCaseDetail: state => state.commonCaseDetail, //病例详情
  getCommonDiagnosisDetail: state => state.commonDiagnosisDetail, //治疗表详情
  getCommonAddProductList: state => state.commonAddProductList, //追加产品列表
  getCommonAddHistoryList: state => state.addHistoryList, //追加历史列表
  getCaseProgressList: state => state.caseProgressList //追加进度列表
};

const mutations = {
  //治疗表详情
  [types.ACTION_GET_CASE_CURE_DETAIL]({ commonCaseCureDetail }, query) {
    extend(commonCaseCureDetail, query);
  },
  //病例详情
  [types.ACTION_GET_COMMON_CASE_DETAIL]({ commonCaseDetail }, query) {
    extend(commonCaseDetail, query);
  },
  //治疗表详情
  [types.ACTION_GET_DIAGNOS_DETAIL]({ commonDiagnosisDetail }, query) {
    extend(commonDiagnosisDetail, query);
  },
  //追加产品列表
  [types.ACTION_GET_ADD_PRODUCT_List]({ commonAddProductList }, query) {
    extend(commonAddProductList, query);
  },
  //追加历史列表
  [types.ACTION_GET_ADD_HISTROY_List]({ addHistoryList }, query) {
    extend(addHistoryList, query);
  },
  //追加进度列表
  [types.ACTION_GET_CASE_PROGRESS_LIST]({ caseProgressList }, query) {
    extend(caseProgressList, query);
  }
};
const caseCure = {
  state,
  getters,
  mutations,
  actions
};
export default caseCure;
