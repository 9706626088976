import fetch from 'common/js/fetch';

// 列表条件查询公告
export function querySmsList(config = {}) {
  return fetch('/core/v1/sms/list', {client: '1',...config});
}
// 列表条件查询公告
export function querySmsListPage(config = {}) {
  return fetch('/core/v1/sms/page', {client: '1', ...config});
}

// 查询公告对象
export function querySmsDetail(id) {
  return fetch(`/core/v1/sms/detail/${id}`);
}

// 列表条件查询文章类型
export function articleTypeList() {
  return fetch('/core/v1/article_type/list', {status: '1'});
}

// 列表条件查询文章
export function articleList(config) {
  return fetch('/core/v1/article/list', {
    status: '1',
    ...config
  });
}

// 查询文章对象
export function articleDetail(id) {
  return fetch(`/core/v1/article/detail/${id}`);
}

// 帮助中心-列表条件查询文章
export function articleListDetail(config) {
  return fetch('/core/v1/article_type/list_detail', {
    status: '1',
    ...config
  });
}

// 阅读/取消阅读
export function smsRead(smsId) {
  return fetch('/core/v1/sms/read', {smsId});
}
