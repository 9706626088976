import actions from './actions';
import * as types from './types';
import {extend} from 'lodash';

const state = {
  //消息列表
  msgList: {
    list: [],
    state: false,
    total:''
  },
};

const getters = {
  getMsgList: (state) => state.msgList,//消息列表
};

const mutations = {
  //消息列表
  [types.ACTION_GET_MSG_LIST]({msgList},query){
    extend(msgList,query)
  },
};
const common = {
  state,
  getters,
  mutations,
  actions
};
export default common;


