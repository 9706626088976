export default {
  isLogin: false,
  menuUrl: '/',
  userMsg: {},
  isHandleMsg: false,
  isModifyUserPhoto: false,
  conditionForQuery: '',
  isShowNews: sessionStorage.getItem('isShowNews') || true,
  language: 'zh_CN',
}
