import * as types from './types';
import {
  caseMessagePage,
} from 'common/api/cases';
import {each} from 'lodash';
import {getUserId} from 'common/js/util';

export  default  {
  //消息列表
  actionGetMsgList({state,commit},query){
    if(state.msgList.state==='loading'){
      return Promise.reject()
    }
    commit(types.ACTION_GET_MSG_LIST,{
      state:'loading'
    });
    return caseMessagePage(query).then((result)=>{
      commit(types.ACTION_GET_MSG_LIST,{
        list: result.list,
        total: result.total,
        state: 'loadend'
      });
      return result;
    }).catch((err)=>{
      commit(types.ACTION_GET_MSG_LIST,{
        state:'loaderr'
      });
      return Promise.reject(err)
    })
  },
}
