import Vue from 'vue';
import Vuex from 'vuex';
import * as getters from './getters';
import state from './state';
import mutations from './mutations';
import actions from './actions';
import caseCure from './case_cure/index';
import common from './common/index';

Vue.use(Vuex);
const store = new Vuex.Store({
  getters,
  state,
  mutations,
  actions,
  modules:{
    caseCure,
    common,
  }
});
export default store;
