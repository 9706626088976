import Vue from "vue";

Vue.directive("dialogDrag", {
  bind(el) {
    //弹框可拉伸最小宽高
    //获取弹框头部（这部分可双击全屏）
    const dialogHeaderEl = el.querySelector(".el-dialog__header");
    const dragDom = el.querySelector(".el-dialog");
    dialogHeaderEl.style.cursor = "move";

    //清除选择头部文字效果
    dialogHeaderEl.onselectstart = new Function("return false");
    //头部加上可拖动cursor
    dialogHeaderEl.style.cursor = "move";

    // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
    const sty = dragDom.currentStyle || window.getComputedStyle(dragDom, null);

    dialogHeaderEl.onmousedown = e => {
      // 鼠标按下，计算当前元素距离可视区的距离
      const disX = e.clientX - dialogHeaderEl.offsetLeft;
      const disY = e.clientY - dialogHeaderEl.offsetTop;
      // 获取到的值带px 正则匹配替换
      let styL, styT;
      // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
      if (sty.left.includes("%")) {
        styL =
          +document.body.clientWidth * (+sty.left.replace(/\%/g, "") / 100);
        styT =
          +document.body.clientHeight * (+sty.top.replace(/\%/g, "") / 100);
      } else {
        styL = +sty.left.replace(/\px/g, "");
        styT = +sty.top.replace(/\px/g, "");
      }
      document.onmousemove = function(e) {
        // 通过事件委托，计算移动的距离
        const l = e.clientX - disX;
        const t = e.clientY - disY;
        // 移动当前元素
        // const dragPosition = dragDom.getBoundingClientRect();
        // const { height, width } = dragPosition;
        dragDom.style.top = `${t + styT}px`;
        dragDom.style.left = `${l + styL}px`;
        // const miunDomWidth =
        //   document.body.clientWidth - (document.body.clientWidth - width);
        // if (t + styT <= 0) {
        //   dragDom.style.top = `0px`;
        // }
        // if (document.body.clientHeight - (t + styT) <= height) {
        //   dragDom.style.top = `${document.body.clientHeight - height}px`;
        // }
        // if (document.body.clientWidth + (l + styL) <= 0) {
        //   dragDom.style.left = `-${document.body.clientWidth}px`;
        // }
        // if (miunDomWidth + (l + styL) >= 0) {
        //   console.log("left", `-${miunDomWidth}px`);
        //   dragDom.style.left = `-${miunDomWidth}px`;
        // }
        dragDom.style.right = `unset`;
        document.body.style.userSelect = "none";
      };
      document.onmouseup = function() {
        document.onmousemove = null;
        document.onmouseup = null;
        document.body.style.userSelect = "unset";
      };
    };
  }
});
