import fetch from 'common/js/fetch';
import {getUserId} from 'common/js/util';

// 根据type查系统参数
export function getSystormKey(key) {
  return fetch('/auth/v1/config/list', {key});
}

// 根据type查系统参数
export function getSystormType(type) {
  return fetch('/auth/v1/config/list', {type});
}
// 查询诊所产品
export function getClinicProductDetail(query) {
  return fetch('/core/v1/clinic_product/detail', query);
}

// 诊所信息
export function getSystormClinicDetail(id) {
  return fetch(`/core/v1/clinic/detail/${id}`);
}

// 根据parentKey查数据字典
export function getDictList(parentKey) {
  return fetch('/auth/v1/dict/list', {parentKey, "sort":"parentKey,orderNo"});
}

// 根据parentKeyList查数据字典
export function getDictAllList(parentKeyList) {
  return fetch('/auth/v1/dict/list', {parentKeyList, "sort":"parentKey,orderNo"});
}

// 获取七牛token
export function getQiniuToken() {
  return fetch('/auth/v1/common/get_qiniu_token');
}

// 修改治疗方案图片
export function modifyPicture(config = {}) {
  return fetch('/core/v1/cure_plan/modify_picture', config);
}

// 产品列表
export function getProductTypeListByDoctor(config = {}) {
  return fetch('/core/v1/product_type/list_by_doctor', { platformType: "0" ,...config});
}



// 列表条件查询城市
export function getCityList() {
  const cName = 'name';
  return new Promise(resolve => {
    fetch('/core/v1/city/list').then(data => {
      const cityData = [];
      let pObj = {}, cList = [], list = [];
      for(let i = 0, len = data.length; i < len; i++) {
        const {id, parentId, levelType} = data[i];
        if(+levelType === 1) {
          pObj = {
            id: id.toString(),
            parentId: parentId.toString(),
            name: data[i][cName],
            sub: []
          };
          cityData.push(pObj);
        }else if(+levelType === 2) {
          cList.push({
            id: id.toString(),
            parentId: parentId.toString(),
            name: data[i][cName],
            sub: []
          });
        }else if(+levelType === 3) {
          list.push({
            id: id.toString(),
            parentId: parentId.toString(),
            name: data[i][cName]
          })
        }
      }
      for(let i = 0, len = cityData.length; i < len; i++) {
        for(let j = 0, cLen = cList.length; j < cLen; j++) {
          if(cityData[i].id === cList[j].parentId) {
            cityData[i].sub.push(cList[j]);
          }
        }
      }
      for(let j = 0, cLen = cList.length; j < cLen; j++) {
        for(let k = 0, l = list.length; k < l; k++) {
          if(cList[j].id === list[k].parentId) {
            cList[j].sub.push(list[k]);
          }
        }
      }
      sessionStorage.setItem('cityData', JSON.stringify(cityData));
      resolve(cityData);
    });
  });
}
