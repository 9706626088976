export default {
  language: {
    name: "中文"
  },
  common: {
    header: {
      ylkjhyn: "隐力科技欢迎您",
      zjdl: "最近登录",
      grzx: "个人中心",
      tcdl: "退出登录",
      zy: "首页",
      bl: "病例",
      blzx: "病例咨询",
      mfyjmx: "免费邮寄模型",
      bzzx: "帮助中心",
      ssts: "搜索患者姓名、病例号、手机号",
      wzdl: "我知道了",
      qpd: "去佩戴",
      qtx: "去填写",
      hfyz: "回复医嘱",
      ckqb: "查看全部",
      qtj: "去提交",
      qsh: "去审核",
      qpz: "批准方案",
      ckyy: "查看原因",
      qck: "请查看"
    },
    message: {
      isLt20M: "上传图片文件不能超过20M",
      supportedFormats: "上传图片文件格式仅支持bmp、jpg 、png",
      xzpb: "新增排班",
      czcg: "操作成功",
      szcg: "设置成功",
      xgcg: "修改成功",
      qtxwz: "请填写完整",
      qxzjl: "请选择记录",
      qxz: "请选择",
      ycl: "已处理",
      sfjx: "请确认是否继续该操作？",
      qd: "确定",
      qr: "确认",
      qx: "取消",
      xz: "新增",
      xg: "修改",
      xq: "详情",
      sc: "删除",
      sz: "设置",
      fh: "返回",
      cx: "查询",
      cz: "重置",
      qb: "全部",
      hqyzm: "获取验证码",
      bdcg: "绑定成功",
      yxbdcg: "邮箱绑定成功",
      dlmmszcg: "登录密码设置成功",
      qtxzqyx: "请正确填写邮箱号",
      qtxxyx: "请正确填写新邮箱号",
      qtxsjh: "请先填写手机号",
      qzqtxsjh: "请正确填写手机号",
      bnbdxtsjh: "不能绑定相同手机号",
      mmbyz: "密码不一致，请确认",
      qtxzqsj: "请正确填写手机号",
      sjhbdcg: "手机号绑定成功",
      zjmmszcg: "资金密码设置成功",
      sjhbnwk: "手机号不能为空，请退出后重试！",
      sjhxgcg: "手机号修改成功",
      sjhbnk: "手机号不能为空，请返回主页后重试！",
      zdfh: "后自动返回",
      nn: "男",
      nv: "女",
      // cs
      ycbbjbkcz: "该牙齿已被标记已缺失，当前不可操作",
      yzc: "已暂存",
      wjsccg: "文件上传成功!",
      wjsc: "文件上传!",
      tpsccg: "图片上传成功!",
      scsb: "上传失败!",
      qxzsctp: "请选择图片上传!",
      tj: "提交",
      qqrww: "请确认无误再提交",
      qtlcczsm: "其他临床操作说明"
    },
    common: {
      down: "下",
      up: "上",
      qd: "确定",
      qx: "取消",
      shxh: "(上颌、下颌，咬合记录)",
      fd: "放大",
      sx: "缩小",
      sxfz: "上下翻转",
      zyfz: "左右翻转",
      zz: "左转",
      yz: "右转",
      xz: "下载",
      blxq: "-病例详情",
      zzzhz: "暂无3D排牙动画",
      zwsj: "暂无数据",
      qsrnr: "描述请限制在2000字内（误删除后，请使用“Ctrl+z”撤销操作）",
      jgwqy: "以下诊所未签约该产品，不可使用"
    },
    component: {
      ybbj: "该牙齿已被标记，当前不可操作",
      lyqyl: "邻面去釉量",
      zlq: "治疗前",
      fj: "附件",
      qsy: "缺失牙/ 假牙空泡",
      y: "右",
      z: "左"
    },
    lcph: {
      cklcph: "查看临床偏好"
    }
  },
  home: {
    home: {
      title: "隐速Grintastic医生端",
      ys: "医生",
      syzs: "所有诊所",
      xzbl: "新建病例",
      dtjbl: "待处理病例",
      dsh: "待收货",
      ysh: "已收货",
      ckqb: "查看全部",
      blh: "病例号",
      zwdcl: "暂无待处理",
      nmhydcl: "您没有需要待处理的病例哦～",
      nhmydtj: "您没有需要待提交的病例哦～",
      nhmydpz: "您没有需要待批准的方案哦～",
      nzshmykd: "您暂时还没有快递信息哦～",
      nzshmyxxx: "您暂时还没有新消息哦～",
      nzshmygg: "您暂时还没有新公告哦～",
      zsmyblxx: "暂时没有病例信息哦～",
      sj3d: "设计阶段 - 3D设计方案",
      kdzz: "快递追踪",
      dpzfa: "待批准方案",
      ckgd: "查看更多",
      ysnh: "医生，您好",
      dtjbls: "待提交病例",
      zlzbl: "治疗中病例",
      ywcbl: "已完成病例",
      xx: "消息中心",
      yszl: "医生资料",
      xtgg: "系统公告",
      ggbt: "公告标题",
      sj: "时间",
      zwgg: "暂无公告",
      yd: "已读",
      wd: "未读",
      zwqycp: "暂无签约产品"
    },
    downloadApp: {},
    publicChildren: {
      msgPage: {
        xxlx: "消息类型",
        fsz: "发送者",
        xxnr: "消息内容",
        tssj: "推送时间",
        cz: "操作",
        zsmyxx: "暂时没有消息哦~",
        qrsc: "确认删除该消息？",
        qzf: "去支付",
        qqr: "去确认"
      }
    },
    personal: {
      personal: {
        title: "个人中心",
        zy: "主页",
        grzx: "个人中心",
        lcbl: "临床偏好",
        grxx: "个人信息",
        dlzh: "登录账号",
        qyjg: "签约机构",
        jj: "简介",
        ybd: "已绑定",
        wbd: "未绑定",
        sjh: "手机号",
        dlmm: "登录密码",
        zjmm: "资金密码",
        pbgl: "排班管理",
        gzr: "工作日",
        sd: "时段",
        szjg: "所在机构",
        zsmypb: "暂时没有排班哦~",
        bztx: "不再提醒",
        qd: "确定",
        lcphtx:
          "尊敬的医生，为了方案设计中心更好的根据您的要求提供治疗方案，请在临床偏好页面编辑相关选项。",
        szlcph: "设置临床偏好"
      },
      scheduling: {
        pb: "排班",
        rzr: "工作日",
        zy: "周一",
        ze: "周二",
        zs: "周三",
        zsi: "周四",
        zw: "周五",
        zl: "周六",
        zr: "周日",
        sd: "时段",
        qssj: "起始时间",
        jssj: "结束时间",
        szjg: "所在机构",
        gzr: "工作日"
      },
      bandEmail: {
        bdyx: "绑定邮箱",
        yxh: "邮箱号",
        yzm: "验证码",
        cxhq: "重新获取",
        hqyzm: "获取验证码"
      },
      bandLoginPaw: {
        dlmm: "登录密码",
        sjh: "手机号",
        yzm: "验证码",
        zcqr: "再次确认"
      },
      bandPhone: {
        bdsj: "绑定手机",
        sjh: "手机号",
        yzm: "验证码"
      },
      bandTradingPaw: {
        zjmm: "资金密码",
        sjh: "手机号",
        zcqr: "再次确认",
        yzm: "验证码"
      },
      bandWeChat: {
        xgsj: "修改手机",
        bdwx: "绑定微信",
        tzjs: "病例通知更及时",
        gzh: "公众号",
        wxzh: "微信账号",
        wxewm: "微信二维码",
        qtxwxh: "请填写微信号"
      },
      editPhone: {
        xgsj: "修改手机",
        sjh: "手机号",
        yzm: "验证码",
        kfdh: "客服电话",
        sbdyzm: "收不到验证码？",
        lxkf: "联系客服",
        xsjh: "新手机号"
      },
      exitEmail: {
        xgyx: "修改邮箱",
        yxh: "邮箱号",
        yzm: "验证码",
        xyxh: "新邮箱号",
        yxxgcg: "邮箱修改成功"
      },
      moreHomePage: {
        xx: "消息",
        title: "病例消息"
      },
      msgDetail: {
        ggxq: "公告详情",
        title: "公告详情"
      }
    }
  },
  cases: {
    cases: {
      xytjxffab: "需要提交修复方案表",
      rxz: "如选择，请务必核对患者下颌后退幅度",
      title: "病例管理",
      xjbl: "新建病例",
      tjbl: "提交病例",
      cxtj: "重新提交",
      zs: "诊所",
      zt: "状态",
      stzt: "请选择状态",
      zxddbh: "咨询订单编号",
      cp: "产品",
      blh: "病例号",
      srblh: "请输入病例号",
      srzxddh: "请输咨询订单编号",
      srhzm: "请输入患者姓名",
      lcqk: "临床情况",
      xsqb: "显示全部",
      yfq: "已废弃",
      fazlz: "方案治疗中",
      xjblsj: "新建病例时间",
      zmhz: "暂时没有患者哦～",
      zlz: "病例治疗中",
      zlzs: "治疗中",
      qpd: "请佩戴第",
      fyt: "副牙套",
      ygd: "已归档",
      qpd02: "去佩戴",
      qsh: "去收货",
      hz: "患者",
      sc: "删除",
      qrsc: "确认删除该病例?",
      gblysc: "该病例已删除",
      s: "岁"
    },
    casesCreate: {
      title: "新建病例",
      xjbl: "新建病例",
      jbxx: "基本信息",
      zdsm: "诊断及说明",
      yxsm: "影像资料及模型",
      lltj: "预览及提交",
      zc: "暂存",
      syy: "上一页",
      xyy: "下一页",
      sftj: "是否提交？",
      rntj: "若您确定提交，资料审核通过后将开始加工生产",
      qdtj: "确定提交",
      qjc: "操作失败，请完善所有项目后再提交"
    },
    inviteFriends: {
      bl: "病例",
      blxq: "病例详情",
      yqsy: "邀请使用APP",
      ysyq: "医生邀您下载APP",
      gdxx: "更多信息详见APP~",
      xztp: "下载图片"
    },
    createDiagnosis: {
      yyzk: "原牙状况",
      qzylzbjjy: "请在牙列中标记假牙",
      qzylzbjqsy: "请在牙列中标记缺失牙。",
      rzzyg:
        "如制作牙冠，请备注单连冠及桥体分段要求，请限制在2000字内(误删除后，使用“Ctrl+Z” 撤销操作)",
      qsrsh: "请输入色号",
      qzylzbjxybyyw: "请在牙列中标记需要备牙牙位。",
      sjmb: "设计目标",
      hd: "厚度(mm)：",
      bzhd: "标准厚度0.35-0.5mm",
      qsrhd: "请输入厚度",
      sfpztmfa: "是否批准美学方案？",
      rnpztmfah: "若您批准美学方案后，将开始加工",
      lczkchlx: "临床状况-错合类型",
      lczkyhgx: "临床状况-咬合关系",
      lczkxljx: "临床状况-相邻间隙",
      lczkycwt: "临床状况-牙齿问题",
      ycxxqy: "牙齿信息(缺牙)",
      ycxxjy: "牙齿信息(假牙)",
      lczk: "临床状况",
      mbyase: "目标牙色",
      mblx: "目标类型",
      mbtmd: "目标透明度",
      cllx: "材料类型",
      sfby: "是否备牙",
      tmhdyq: "贴面厚度要求(mm)",
      xfsj: "修复设计",
      mqbzbsbdysh: "明确标注比色板对应色号",
      sxyw: "所需牙位",
      yhgx: "咬合关系",
      ljx: "邻间隙",
      tm: "贴面",
      guan: "冠",
      qt: "嵌体",
      qzylzbjsxyw: "请在牙列中标记所需牙位",
      yysx: "原牙色系",
      yymry: "远移乳磨牙",
      kxzqtxxmssx: "可选择其他选项描述色系",
      kxzqtxxmsys: "可选择其他选项描述颜色",
      mbys: "目标颜色",
      sfphzzzkdbw: "是否配合种植支抗钉-拟植入种植支抗钉的部位",
      sfphzzzkdzy: "是否配合种植支抗钉-拟植入种植支抗钉的作用",
      ymwl: "牙面纹路",
      sjyq: "设计要求",
      exgjsfczyc: "颞下颌关节是否存在异常",
      exgjsfczycinit:
        "若有颞下颌关节区疼痛、关节弹响明显和下颌运动障碍等症状，建议先行专科检查",
      yzsfzc: "牙周是否存在异常",
      yzsfzcinit: "中、重度牙周炎，建议先行专科检查。",
      threedsjhqd: "3D设计后再确定",
      zc: "左侧",
      yc: "右侧",
      jzzk: "矫治状况",
      hxgx: "横向关系",
      blxg: "不良习惯",
      txt1: "主诉",
      zyjzmb: "主要矫治目标",
      yczkasfl: "临床状况-安氏分类",
      yczkchlx: "临床状况-错合类型",
      xfsjyysx: "修复设计-原牙色系",
      xfsjsxyw: "修复设计-所需牙位",
      xfsjmbys: "修复设计-目标颜色",
      xfsjymwl: "修复设计-牙面纹路",
      xfsjsjyq: "修复设计-设计要求",
      asfl: "安氏分类",
      gxfl: "骨性分类",
      chlx: "错合类型",
      ycwt: "牙齿问题",
      ggwt: "颌骨问题",
      gs: "其他情况请限制在1000字内（误删除后，使用“Ctrl+z”撤销操作）",
      gs2000: "其他情况请限制在2000字内（误删除后，使用“Ctrl+z”撤销操作）",
      sjfasmrgbszyx:
        "设计方案时，默认关闭散在间隙，如有必要请标记预留修复间隙。",
      txt2: "临床状况",
      lcqk: "临床情况",
      qtqk: "其他情况",
      txt3: "拟矫治牙颌",
      txt3ms: "即使单颌矫治，也需提交全颌硅胶印模或数字文件",
      ckse: "参考上颌协调弓形",
      xehx: "仅考虑矫正下颌的弓形",
      txt4: "牙齿信息",
      txt4ms:
        "下方牙列式为恒牙全部萌出的默认状态，请在牙列中标记缺失牙、滞留的乳牙。",
      gaptxt3: "下方牙列式为恒牙全部萌出的默认状态，请在牙列中标记缺失牙",
      txt5: "不可移动牙齿",
      txt5ms: "烤瓷牙，修复桥体，种植牙等无法移动牙齿",
      wu: "无",
      ycbk: "以下牙齿不可移动",
      txt6: "不可设计附件牙齿",
      txt6sm: "烤瓷牙，修复桥体等无法黏贴附件",
      yxbksj: "以下牙齿不可设计附件",
      txt7: "矢状关系",
      zuo: "左",
      ff: "方法",
      you: "右",
      s: "是",
      txt8: "覆盖",
      txt9: "覆合",
      sfxybdjzq: "是否需要被动矫治器",
      sfxybdjzqts:
        "被动矫治器:仅适用于矫正双颌的病例，上下颌非同一时间结束矫正，率先结束的一颌进入被动矫治器阶段，起保持和配合对颌后续矫正的作用。",
      wc: "维持",
      sjhzqr: "3D设计后再确定",
      ydqysghy: "压低前牙并升高后牙",
      se: "上颌",
      qh: "全颌",
      xe: "下颌",
      txt10: "前牙反合/对刃合",
      qyfh: "前牙反合",
      bjz: "不纠正",
      txt11: "后牙反合或锁合",
      gszx: "改善中线",
      zcyd: "向患者左侧移动",
      ycyd: "向患者右侧移动",
      srjtsz: "请输入具体数值",
      txt12: "中线",
      txt13: "间隙的矫治",
      yljg: "预留间隙",
      txt14: "拥挤的矫治",
      jcyjsx: "解除拥挤所需临床操作",
      qtlczc: "其他临床操作",
      backjclc: "拥挤的矫治-解除拥挤所需临床操作",
      backjcqt: "拥挤的矫治-其他临床操作",
      hzsfjsbczc:
        "患者是否接受拔除智齿（解除拥挤/纠正后牙咬合关系可能存在磨牙远移）",
      hzsfjsbcqmy: "患者是否接受拔除前磨牙（为解除中度以上拥挤，前突，弓外牙）",
      hzsfjsbchy: "患者是否接受拔除龋坏的牙齿",
      hzsfjslmqy: "患者是否接受邻面去釉",
      sfyycxf: "是否有牙齿需要修复以及修复时期",
      zssfjb: "诊所是否具备微种植体支抗植入术",
      zssfjbps: "诊所是否具备CBCT拍摄资质",
      hzsfyzjs: "患者是否有正畸史",
      jcyjjzfs: "解除拥挤的方式",
      kk: "扩弓",
      ss: "首选",
      kx: "可选",
      bk: "不可",
      cq: "唇倾",
      lmqz: "邻面去釉",
      qy: "前牙",
      zchy: "左侧后牙",
      ychy: "右侧后牙",
      yymy: "远移磨牙",
      yymyms: "注：若勾选远移磨牙，可能需配合种植支抗钉，并且X光为必选",
      by: "拔牙",
      bb: "不拔",
      txt15: "是否配合种植支抗钉",
      srms: "描述请限制在1000字内（误删除后，使用“Ctrl+z”撤销操作）",
      txt16: "颞下颌关节是否存在异常",
      txt17: "特殊说明",
      hxgjtswz:
        "请添加对颞下颌关节异常的症状描述和设计要求，描述请限制在2000字内（误删除后，使用“Ctrl+z”撤销操作）",
      qtjdxjzq:
        "请添加对被动矫治器的设计要求，描述请限制在2000字以内（误删可使用‘ctrl + z’撤销误操作）",
      zx: "左下",
      yx: "右下",
      zs: "左上",
      ys: "右上",
      sfxyfzmy: "是否需要预放置第一磨牙的萌出补偿",
      yljgw: "预留间隙为",
      rxztip: "如选择，则 “拥挤的矫治” 中需选远移磨牙",
      yjdjzzyymy: "“拥挤的矫治” 中需选远移磨牙",
      yjmcyc: "请在下方牙列式中标注出已萌出的牙齿",
      bzbkydyc: "请在下方牙列式中标注出不可移动的牙齿",
      kcydbkyd: "烤瓷牙，修复桥体，种植牙等无法移动牙齿",
      kcyfj: "烤瓷牙，修复桥体等无法粘结附件",
      bzbksjyc: "请在下方牙列式中标注出不可设计附件的牙齿",
      sjfas:
        "设计方案时，默认关闭恒牙间隙，保留乳牙间隙，如有特殊要求请在下方牙位图内标记",
      babysjfas:
        "设计方案时，默认保留乳牙间隙。如有特殊要求请在下方牙位图内标记",
      kdjx: "扩大间隙",
      sxjx: "缩小间隙",
      wcjx: "维持间隙",
      gbjx: "关闭间隙",
      maintain: "维持",
      spacing: "间隙",
      expansion: "扩大",
      retraction: "缩小",
      closure: "关闭",
      total: "双侧共",
      zcxzsm: "左侧补充说明限制在200字",
      ycxzsm: "右侧补充说明限制在200字",
      up: "上",
      down: "下",
      zyxgbx: "注：若勾选，则X光为必选",
      zyxry: "注：限乳牙",
      kdjxz: "扩大间隙至",
      sxjxz: "缩小间隙至",
      jxl: "间隙量",
      qyl: "去釉量",
      toglaze: "去釉",
      controlgap: "调整间隙",
      qubtjjl: "请务必核对患者下颌后退幅度",
      nzrzzkd: "拟植入种植支抗钉的部位",
      nzrzzkdzy: "拟植入种植支抗钉的作用",
      jsdgjz: "即使单颌矫治，也需提交全颌硅橡胶印模或数字文件",
      bcyxyc: "拔除以下牙齿"
    },
    createImage: {
      zcmwxx: "左侧面微笑像",
      zmwxx: "正面微笑像",
      ycmwxx: "右侧面微笑像",
      yhx: "咬合像",
      khx: "开合像",
      czrxwt: "存在如下问题",
      fzcg: "复制成功!",
      yemx: "牙颌模型",
      mxzqbz: "模型制取标准和导出数字文件教程",
      gxjmx: "硅橡胶模型",
      gxjym: "硅橡胶印膜",
      yscblh: "aismile已为你生成病例序号",
      fkd: "发快递",
      blxh: "病例序号",
      szmxwz: "数字模型文件",
      zxyj: "上门取件",
      yyyj: "自行邮寄",
      zdxz: "超过最大限制",
      gxjyh: "硅橡胶咬合记录",
      bdsc: "本地上传",
      se: "上颌",
      xe: "下颌",
      scstl: "请上传带咬合的STL文件",
      wj: "文件",
      lstj: "历史提交",
      sczip: "请上传ZIP或者rar文件",
      qt: "其他",
      kqnzp: "面像及口内照片",
      zppsbz: "照片拍摄标准",
      ktztp: "可以从电脑拖拽图片",
      sctz: "点击上传/拖拽图片",
      cmx: "侧面像",
      zmx: "正面像",
      wxx: "微笑像",
      sylx: "上牙列像",
      xylx: "下牙列像",
      knzcyx: "口内右侧位像",
      knzwx: "口内正位像",
      knycyx: "口内左侧位像",
      xgp: "X光片",
      xgpbz: "X光片拍摄标准",
      dcp: "全颌曲面断层片",
      dwp: "头颅侧位定位片",
      yxzl: "其他影像资料",
      zdten: "最多上传10张图片",
      ck: "查看",
      qyfgx: "前牙覆盖像",
      qyfhfgx: "前牙覆合覆盖像"
    },
    createPreview: {
      wwcts: "您还有以下项目待完善，请确认无误后再提交",
      jzzl: "诊断及说明",
      yxzlsm: "影像资料及模型",
      bltxwz: "病例已填写完成，请确认病例内容",
      zs: "主诉",
      ycwt: "牙齿问题",
      egwt: "颌骨问题",
      zsqk: "主诉情况",
      lczk: "临床状况",
      qtqk: "其他情况",
      njzye: "拟矫治牙颌",
      ycxx: "牙齿信息",
      jzgx: "矢状关系",
      zuo: "左",
      you: "右",
      fg: "覆盖",
      fh: "覆合",
      fhsm: "覆合说明",
      yd: "前牙反合/对刃合",
      bkydyc: "不可移动牙齿",
      bkfj: "不可设计附件牙齿",
      hsh: "后牙反合或锁合",
      zx: "中线",
      jxzl: "间隙的矫治",
      yjzl: "拥挤的矫治",
      sfphz: "是否配合种植支抗钉",
      hgj: "颞下颌关节是否存在异常",
      tssm: "特殊说明",
      tjcg: "提交成功",
      she: "上颌",
      xhe: "下颌",
      yjshe: "拥挤的矫治（上颌）",
      kg: "扩弓",
      shcq: "上颌唇倾",
      lmqy: "邻面去釉",
      shlmqusm: "上颌邻面去釉说明",
      yymy: "远移磨牙",
      yymry: "远移乳磨牙",
      yjjxhe: "拥挤的矫治（下颌）",
      xhkg: "下颌扩弓",
      xhcq: "下颌唇倾",
      xhlmqy: "下颌邻面去釉说明",
      qksm: "情况说明",
      qy: "前牙",
      ychy: "右侧后牙",
      zchy: "左侧后牙",
      bk: "不可",
      ggphtext: "您可以针对该患者更改偏好，临床偏好将应用于您的治疗",
      jzqyfh: "纠正前牙反合",
      jzqydrh: "纠正前牙对刃合",
      jz: "纠正"
    },
    createUser: {
      tcbdcp: "套餐绑定产品",
      qsrtcbdcp: "请选择套餐绑定产品",
      znjx: "智能解析",
      jx: "解析",
      qk: "清空",
      jxhqhsxx: "解析后请核实信息是否正确",
      znjxplaceholder:
        "智能解析：粘贴或输入整段文字，自动识别姓名、手机号、地址，如：张xx  135xxxxxxxx 浙江省杭州市下城区xx大厦xx号",
      s: "是",
      f: "否",
      hzjsyt: "收货类型",
      sjrxm: "收件人姓名",
      zssh: "诊所收货",
      trsh: "他人收货",
      sjrsjhm: "收件人电话",
      qh: "区号",
      qsrsjrsjhm: "请输入收件人手机号码",
      qsrlxdh: "请正确填写联系电话",
      qtxsjrxm: "请填写收件人姓名",
      qtxssqjdz: "请填写省市区及地址",
      qtxxm: "请填写收件人姓名，手机号码和地址",
      hzxx: "患者信息",
      jbxx: "基本信息",
      hzh: "患者号",
      hzmc: "患者姓名",
      qsr: "请输入",
      xb: "性别",
      n: "男",
      v: "女",
      csrq: "出生日期",
      xzrq: "选择日期",
      sjhm: "手机号码",
      dz: "地址",
      sf: "省份",
      cs: "城市",
      qx: "区县",
      xxdz: "详细地址",
      zsxz: "诊所选择",
      zsmc: "诊所名称",
      zsjs: "诊所介绍",
      cpxz: "产品选择",
      cpmc: "产品名称",
      cpjs: "产品介绍",
      qtxhz: "请填写患者名称",
      qtxxb: "请选择患者性别",
      qtxcs: "请填写患者出生日期",
      qtxsjh: "请正确填写手机号",
      qxzzs: "请选择诊所",
      qxzcp: "请选择产品",
      yzc: "已暂存",
      gcpwqy: "未签约该产品不可选择,请选择已签约该产品的机构",
      zjcp: "不支持诊所切换",
      ggcpbzcqh: "更改产品，不支持诊所切换",
      nlbxdyfive: "年龄必须大于五周岁",
      nlthreedsl: "年龄3-16周岁",
      nlthreetosix: "年龄3-6周岁",
      nlthreetothreeteen: "年龄3-13周岁"
    },
    theDelivery: {
      txkdxx: "填写快递信息",
      yjsj: "邮寄时间",
      qxzwlgs: "请选择物流公司",
      qsrwldh: "请输入物流单号",
      qxzfhsj: "请选择邮寄时间",
      xzsj: "选择时间",
      fhd: "发快递",
      wjmc: "物件名称",
      qtxwjmc: "请填写物件名称",
      sjr: "收件人",
      qtxsjr: "请填写收件人",
      sjrsjh: "收件人手机号",
      qtxsjh: "请填写收件人手机号",
      dz: "地址",
      wlgs: "物流公司",
      qxz: "请选择",
      wldh: "物流单号",
      qtxwldh: "请填写物流单号",
      fhsj: "发货时间",
      mxbz: "请务必在模型上标注患者姓名和病例号",
      xzrq: "选择日期",
      qx: "取消",
      qr: "确定",
      dgjmx: "的硅橡胶印模",
      xsczl: "请先生成设计方案",
      zndxhsz: "只能输入大写字母和数字",
      txwz: "请填写完整",
      srsjh: "请正确填写手机号",
      czcg: "操作成功"
    },
    maintain: {
      jzwcqk: "矫治完成情况",
      dzbcqdzfs: "定制保持器-定制方式",
      dzbcqczxz: "定制保持器-材质选择",
      dzbcqsfmcfj: "定制保持器-是否磨除附件",
      qzsrkntxqtyy: "请在输入框内填写其他原因",
      shscsfxyfzyhdb: "上颌舌侧是否需要放置咬合导板",
      scssfxyylwz: "舌侧丝是否需要预留位置",
      sfxyccmxqg: "是否需要唇侧美学切割",
      qyqkxzz: "其他情况限制在2000字内（误删除后，请使用“Ctrl+z”撤销操作）",
      jzwcrq: "矫治完成日期",
      dzbcq: "定制保持器",
      sdblwczlh: "注：收到病例完成资料后，我公司将免费提供保持器一副",
      dzfs: "定制方式",
      czxz: "材质选择",
      sfmcfj: "是否磨除附件",
      jzwchmxjknzp: "矫正完成后面像及口内照片",
      jzwchxgp: "矫正完成后X光片",
      yhmx: "牙颌模型",
      xzrq: "选择日期",
      qxzcz: "请选择材质",
      ktztp: "可以从电脑拖拽图片",
      bltxwz: "病例已填写完成，请确认病例内容",
      wwcts: "您还有以下项目待完善，请确认无误后再提交",
      zsmc: "诊所名称",
      cpmc: "产品名称",
      zc: "暂存",
      syy: "上一页",
      xyy: "下一页",
      tj: "提交",
      lltj: "预览及提交",
      qrwcsynzqk: "确认完成属于哪种情况",
      wcqdb: "完成病例",
      xy: "需要",
      bxy: "不需要"
    }
  },
  casesDetail: {
    casesChildren: {
      operationChildren: {
        fabh: "方案编号",
        czsyb: "操作示意表",
        d3py: "3D排牙动画",
        zlb: "处方表",
        yxzl: "影像资料",
        lsxgyj: "历史修改建议",
        lccz: "临床操作提醒",
        fzjkyj: "复诊监控意见",
        dblzl: " 的病例资料",
        yxzljmx: "影像资料及模型",
        xgyj: "医生修改意见",
        zswjy: "暂时没有历史建议哦",
        zsmylsfa: "暂时没有历史方案哦~",
        hzxm: "患者姓名",
        sjrq: "设计日期",
        skjzbz: "上颌矫治步骤",
        xkjzbz: "下颌矫治步骤",
        ylzj: "医疗总结",
        yjjzzq: "预计矫治周期：",
        y: "月",
        ylkjjy: "隐力科技建议",
        ysjy: "医生意见",
        nyqrcfa: "您已确认此方案",
        nypzcfa: "您已批准此方案"
      },
      textPlanChildren: {
        dqfa: "当前方案",
        lsfa: "历史方案"
      },
      allLogistics: {
        title: "物流跟踪",
        qbwl: "全部物流",
        ydh: "运单号",
        wjnr: "文件内容",
        ckxq: "查看详情",
        dsh: "待收货",
        ysh: "已收货"
      },
      amendments: {
        tjxgyj: "提交修改意见",
        xgyj: "修改意见",
        zcwrxtj:
          "暂存完成，如需要提交，请回到提交页面，点击提交修改意见完成提交.",
        myfa: "模拟方案，实际治疗效果可能不同",
        ckls: "查看历史记录",
        srxgyj: "请在此输入修改意见，500字以内。",
        xgyjlr:
          "您提交的修改意见将作为正式的设计要求录入系统，asmile将按照您的要求尽快做出设计更改",
        qxtx: "请填写修改意见",
        ckbl: "查看病例",
        qdwz: "查看目标方案",
        fhzl: "复核设计方案",
        czcg: "操作成功",
        czsb: "操作失败，请退出后重试！"
      },
      casesDoKeep: {
        zbc: "做保持",
        blh: "病例号",
        djf: "第几副",
        ycxsm: "您已重新扫描",
        cxsm: "重新扫描",
        ys: "颜色",
        cz: "材质",
        qxz: "请选择",
        qtxwz: "请填写完整",
        qsrys: "请输入颜色",
        qxzcz: "请选择材质",
        qrjx: "请确认是否继续该操作？",
        qx: "取消",
        qd: "确定"
      },
      casesDone: {
        wcbl: "完成病例",
        gdbl: "归档病例",
        blh: "病例号"
      },
      casesToScan: {
        zbc: "做保持",
        cxsm: "重新扫描",
        yhmx: "牙颌模型",
        mxbz: "模型制取标准",
        gxmx: "硅橡胶模型",
        xgjym: "硅橡胶印膜",
        blxh: "aismile已为你生成病例序号",
        fkd: "发快递",
        blxh01: "病例序号",
        gjyhe: "硅橡胶咬合记录",
        szmx: "数字模型文件",
        xzgjym: "请选择硅橡胶模型",
        fzcg: "复制成功",
        xzzip: "请选择ZIP或者RAR文件上传!"
      },
      casesTransfer: {
        zrbl: "转让病例",
        blh: "病例号",
        zrzs: "转让诊所",
        zrys: "转让医生",
        zrly: "转让理由",
        qsrwz: "请输入完整！",
        gzswys: "该诊所下并无医生，请选择其他诊所！"
      },
      hisEditOpinion: {
        lsxgyj: "历史修改建议",
        fabh: "方案编号"
      },
      hisTextPlan: {
        wzlsxgyj: "目标方案历史修改意见",
        fabh: "方案编号"
      },
      lookWearingRecord: {
        ckpdjl: "查看佩戴记录",
        dqpd: "当前佩戴",
        d: "第",
        pc: "批次",
        ytzbs: "牙套总步数",
        f: "副",
        cmpzh: "此膜片组合为方案设计推荐，如需更改可自行选择，方案批准后生效",
        rnpzh: "若您批准3D治疗方案后，公司将开始加工矫治器",
        gsksjg: "膜片组合为方案设计推荐，如需更改点击修改",
        kspdsj: "开始佩戴时间",
        yjssj: "拟结束时间",
        dqpchs: "当前批次还剩",
        t: "套",
        xytpd: "下一套佩戴提醒时间",
        ggtxsj: "更改提醒时间",
        xzrq: "选择日期",
        zwpdjl: "暂无佩戴记录"
      },
      nextProduction: {
        xypsc: "下一批生产",
        fhsyy: "返回上一页",
        blh: "病例号",
        cxtz: "重新调整",
        wxtz: "继续发货"
      },
      paymentScheme: {
        zfsjf: "支付方案设计费",
        zfsc: "支付生产制造费",
        blh: "病例号",
        fasjf: "方案设计费",
        sczzf: "生产制造费",
        scf: "生产费",
        y: "元",
        zhye: "账户余额",
        sxye: "授信余额",
        srzfmm: "输入支付密码",
        zzfy: "*支付后即可查看完整设计方案",
        jrsczz: "*支付后厂家进入生产制作",
        zfsb: "支付失败，请退出重试",
        zfcg: "支付成功",
        qtxzfmm: "请填写支付密码",
        qxszfmm: "请先设置支付密码",
        yebz: "余额不足，请充值",
        sxyebz: "账户余额不足，请联系诊所管理员充值。",
        qrzf: "确认支付",
        dzfscf: "待支付生产费",
        dqrsc: "待确认生产",
        sfqdzfscf: "是否确定支付生产费",
        zfhgsksjgsc: "支付后公司开始加工生产",
        qrhksjg: "确认后开始加工生产",
        qrsc: "确认生产",
        sfqrsc: "是否确认生产"
      },
      restartCases: {
        cqbl: "重启病例",
        blh: "病例号",
        srzfmm: "输入支付密码",
        cqcztip: "*重启病例，为风险操作,一旦重启病例将需要从0开始",
        qsrzfmm: "请输入支付密码！"
      },
      reviewPlan: {
        fhzlfa: "复核设计方案",
        blh: "病例号",
        pzcfa: "批准此方案",
        qrcfa: "确认此方案",
        tjxgyj: "提交修改意见",
        sfjxpz: "是否确认目标位方案？",
        sfpz3d: "是否批准3D设计方案?",
        rnpzkssc3d: "若您批准3D设计方案后，公司将开始加工矫治器",
        rrpz: "仍然批准",
        rnpzkssc: "若您确认目标方案后，公司将开始设计3D设计方案"
      },
      reviewTextPlan: {
        qdwzfa: "确定目标方案"
      },
      sendAdvice: {
        fsyz: "发送医嘱",
        yzlx: "医嘱类型",
        smsj: "上门时间",
        yznr: "医嘱内容",
        fs: "发送",
        lsyz: "历史医嘱",
        zwyz: "暂无医嘱消息",
        qxzyzlx: "请选择医嘱类型",
        qxzsmsj: "请选择上门时间",
        qsryznr: "请输入医嘱内容",
        fscg: "发送成功"
      },
      viewLogistics: {
        ckwl: "查看物流",
        ydh: "运单号",
        wjnr: "文件内容",
        qrsh: "确认收货",
        zswwl: "暂时没有物流哦",
        dsh: "待收货",
        ysh: "已收货"
      },
      wearingBraces: {
        pdyt: "佩戴牙套",
        blh: "病例号",
        djfyt: "第几副牙套",
        pdkssj: "佩戴开始时间",
        yjssj: "拟结束时间",
        pdssm: "佩戴时说明",
        fs: "发送",
        pdjl: "佩戴记录",
        d: "第",
        t: "套",
        zwpd: "暂无佩戴记录",
        qsryt: "请输入第几副牙套",
        qxzpdsj: "请选择佩戴开始时间",
        xzpdjs: "请选择佩戴结束时间"
      },
      common: {
        bl: "病例",
        blxq: "病例详情",
        fh: "返回",
        qr: "确认",
        czcg: "操作成功",
        czsb: "操作失败，请退出后重试！",
        czsbErr: "出错了，请关掉窗口后重新操作！",
        qrjx: "请确认是否继续该操作？",
        qx: "取消",
        qd: "确定",
        qxz: "请选择",
        fhsyy: "返回上一页",
        qsr: "请输入",
        xzrq: "选择日期"
      }
    },
    childrenPublic: {
      backStep: {
        fhsyy: "返回上一页"
      }
    },
    casesDetail: {
      kqrdth: "开启iRDT后，方案设计中心将重新给出新的方案设计",
      kqrdt: "开启iRDT",
      kqrdtxyws:
        "开启iRDT需要完善【诊断及说明】，提交后方案设计中心将重新给出新的方案设计",
      ddzl: "订单资料",
      ddh: "订单号",
      ddxm: "订单姓名",
      title: "病例信息",
      blh: "病例号",
      ck3d: "查看3D",
      lcqk: "临床情况",
      tjbz: "备注",
      qsr: "请输入...",
      bc: "保存",
      qx: "取消",
      xg: "修改",
      lc: "待办",
      cz: "事项",
      qpd: "去佩戴",
      yfqkcq: "已废弃，可重启",
      blzl: "病例资料",
      zlk: "资料库",
      sjjjd: "收集阶段",
      z: "组",
      f: "份",
      g: "个",
      yxzl: "影像资料",
      zlfa: "设计方案",
      tdfa: "3D方案",
      tdzlfa: "3D设计方案",
      mbwfa: "目标位方案",
      sjjd: "设计阶段",
      ypz: "已批准",
      wpz: "待批准",
      yqr: "已确认",
      wqr: "待确认",
      yfk: "已反馈",
      sjjd01: "收集阶段",
      jd: "阶段",
      wjm: "文件名",
      scjd: "生产阶段",
      ytjd: "牙套进度",
      zljd: "治疗阶段",
      wcjd: "完成阶段",
      bcjd: "保持阶段",
      scbcq: "生产保持器",
      wlgz: "物流跟踪",
      threedsj: "3D设计方案",
      mxfa: "美学方案",
      threedsjsm: "3D矫治说明",
      fasm: "方案说明",
      ckqb: "查看全部",
      fh: "发货",
      ckwl: "查看物流",
      zwwlxx: "暂无物流信息",
      bljd: "病例进度",
      ckpdjl: "查看佩戴记录",
      dtj: "待提交",
      zlz: "治疗中",
      ywc: "已完成",
      ygd: "已归档",
      yfq: "已废弃",
      hzhd: "患者互动",
      yqsyapp: "邀请使用APP",
      pdyt: "佩戴牙套",
      fsyz: "发送医嘱",
      fasj: "方案设计",
      tjzlb: "提交治疗表",
      zjsc: "追加生产",
      zjcp: "追加产品",
      cklszj: "查看历史追加",
      zjscjd: "追加生产阶段",
      wdsj: "未到时间",
      yzt: "已暂停",
      qdwzfa: "确定目标方案",
      zfsjf: "支付设计费",
      qdzlfa: "批准设计方案",
      pztdfa: "批准3D设计方案",
      pzmxsjfa: "批准美学方案",
      scjg: "生产加工",
      zfscf: "支付生产费",
      jxfh: "继续发货",
      gxjyj: "硅橡胶邮寄",
      blcz: "病例操作",
      ztm: "转修复",
      wcbl: "完成病例",
      gdbl: "归档病例",
      cqbl: "重启病例",
      zscz: "诊所操作",
      zrbl: "转让病例",
      gztbkcz: "该状态不可进行该操作",
      qtxbz: "请填写备注",
      bztjcg: "备注添加成功",
      lcczsm: "临床操作说明",
      bu: "步",
      shzz: "上颌总共",
      xhzz: "下颌总共",
      gcffz: "工厂发放至",
      shd: "上颌第",
      xhd: "下颌第",
      bcjg: "本次加工",
      sfhxsq: "是否提交加工后续申请",
      ggdz: "如需更改地址请联系公司客服人员",
      jzqsh: "矫治器收货",
      jghx: "加工后续"
    },
    publicCases: {
      casesDiagnosis: {
        zdyq: "诊断及说明"
      },
      casesPic: {
        yxzl: "影像资料及模型",
        mxkq: "面像及口内照片",
        xpg: "X光片",
        qtyx: "其他影像资料"
      },
      casesUser: {
        jbxx: "基本信息",
        n: "男",
        v: "女",
        yljg: "医疗机构",
        blxh: "病例序号",
        fzcg: "复制成功!"
      }
    },
    clinicalPreferences: {
      qqlcph: "全局临床偏好",
      cptdph: "产品特定偏好",
      qbzd: "请备注到方案特殊说明栏",
      phjs:
        "以下所列临床偏好默认应用于您的所有治疗。提交患者病例时，您可以针对单个病例更改偏好。",
      cplcph: "以下临床偏好仅会应用于您的 第一阶段 治疗。",
      smsRTD: "什么是iRDT协助",
      kqRTD:
        "开启iRDT协助后，医生提交的治疗表将经过经验丰富的正畸医生进行治疗表的完善，以及方案的审阅，最终呈现给医生批准。",
      bc: "保存",
      dx: "单选",
      duox: "多选",
      lcphsz: "临床偏好设置成功",
      qsr: "请输入",
      RTD: "iRDT协助",
      sfxyRTD: "是否需要iRDT协助？",
      xy: "需要",
      bxy: "不需要"
    },
    view3d: {
      hz: "患者",
      blh: "病例号",
      lsfa: "历史方案",
      jzsm: "处方表",
      blzl: "病例资料",
      zlb: "治疗表",
      zp: "照片",
      zsmytp: "暂时没有图片"
    }
  },
  helpCenter: {
    title: "帮助中心"
  },
  login: {
    login: {
      etzhxtxzj: "儿童综合系统性早矫",
      hyld: "欢迎来到",
      yxjzyspt: "MOST隐形矫治医生平台",
      dljszc: "多乐天祥临床技术集团和隐力科技提供支持",
      title: "隐速科技-病例管理系统",
      srzh: "请输入账号",
      srmm: "请输入密码",
      dl: "登 录",
      cz: "重置",
      wjmm: "忘记密码",
      xzapp: "下载APP",
      jzmm: "记住密码",
      xgmm: "修改密码",
      szh: "数字化正畸智能服务平台",
      jszc: "由隐速科技提供技术支持 电话：4000 538 818",
      gfwz: "官方网址：www.aigrin.cn",
      hyldylpt: "欢迎来到隐速科技InnoBuilder平台",
      qsryhm: "请输入用户名"
    },
    retrievePaw: {
      wjmm: "忘记密码",
      sjh: "手 机 号",
      srsjh: "请输入手机号",
      cxhq: "重新获取",
      hqyzm: "获取验证码",
      yzm: "验 证 码",
      sryzm: "请输入验证码",
      xmm: "新 密 码",
      srxmm: "请输入新密码",
      qrmm: "确认密码",
      qqrmm: "请确认密码",
      qxtxsjh: "请先填写手机号",
      qzqtx: "请正确填写手机号",
      mmbyz: "密码不一致，请确认",
      qd: "确定",
      fh: "返回",
      czcg: "操作成功！",
      xmminit: "请设置6~20位密码,可包含字母、数字"
    },
    downloadApp: {
      smxz: "扫码下载"
    },
    common: {
      qtxwz: "请填写完整"
    }
  },
  birdie: {
    qxzjjdz: "请选择寄件地址",
    dqjgdz: "当前机构地址",
    gtxd:
      "此地址是当前机构共用。如果选择此地址,当机构内其他医生/助理/前台下单时,可以为您一同下单",
    grdz: "个人地址",
    xzgrdz: "新增个人地址",
    xz: "选择",
    bj: "编辑",
    djsmx: "待寄送模型",
    yxdmx: "已下单模型",
    xzjg: "选择机构",
    jgewm: "机构二维码",
    xm: "姓名",
    qgxbl: "请勾选需要下单病例",
    jxtj: "继续添加",
    xd: "下单",
    bctp: "保存图片",
    dy: "打印",
    ylkjmfjs: "隐力科技免费寄送模型",
    smjsmx: "扫码寄送模型",
    smdkxd: "扫码打开下单页",
    gjmxgx: "根据模型盒中的病例信息，勾选病例下单",
    xdwcdd: "下单完成后等待快递上门服务",
    wlsmzq:
      "物流人员未上门取件之前，支持取消订单，其他情况则不能取消订单，特殊情况，请联系客服人员。取消后的订单可以再次下单寄送。",
    mx: "模型",
    qxjsh: "取消寄送完成后，如需再次寄送，可以继续下单",
    qdqxjs: "确定取消寄送",
    xzgedz: "新增个人地址",
    bjgedz: "编辑个人地址",
    jjr: "寄件人",
    xzdq: "选择地区",
    lxdh: "联系电话",
    gddh: "固定电话",
    qhsryw: "区号输入有误",
    dhhmsryw: "电话号码输入有误",
    qxzjddz: "请填写所在街道及详细地址",
    qxzdq: "请选择所在地区",
    dhgscw: "电话格式有误",
    sjrsjhmyw: "收件人手机号码有误",
    qtxsjh: "请填写手机号",
    qtxjjr: "请填写寄件人",
    wlxx: "物流信息",
    tjjsmx: "添加寄送模型",
    tj: "添加",
    qrddxx: "确认订单信息",
    ydwcdd: "下单完成等待快递人员上门",
    nkycx: "您可以在下单模型中查询物流信息",
    cxxd: "重新下单",
    xdsb: "下单失败",
    smsj: "上门时间",
    jg: "机构",
    qrxd: "确认下单",
    rhsy: "如何使用",
    dqjgzwmx: "当前机构暂无模型需要寄送",
    jt: "今天",
    mt: "明天",
    ht: "后天",
    qsrblhxm: "请输入姓名或者病例号",
    bjjjjjdz: "编辑机构寄件地址",
    qwbbz: "请务必在模型上标注姓名和病例号",
    qtxlxr: "请填写联系人姓名",
    yjg: "有",
    qx: "全选",
    yng: "个病例，寄件地址为个人地址，请切换地址下单",
    yjgdz: "个病例，寄件地址为机构地址，请切换地址下单",
    ts: "提示",
    qxgx: "请先勾选再下单",
    lxsyn: "两个小时以内",
    ckyxdmx: "查看已下单模型",
    sfqrxd: "是否确认下单",
    qxgxsj: "请先选择上门时间",
    yqx: "已取消",
    qxjs: "取消寄送",
    qjm: "取件码",
    zdsg: "最多新增3个地址",
    dqgrdz: "当前个人地址",
    qh: "区号",
    dhhm: "电话号码",
    fj: "分机（可选）",
    qtxqh: "请填写区号",
    qtxdhhm: "请填写电话号码",
    gr: "个人",
    qsrswsz: "请输入4位数字",
    qsrbwsz: "请输入8位数字",
    xdz: "下单中",
    xdsj: "下单时间",
    qjhm: "取件码",
    yysmsj: "预约上门时间",
    kdxx: "快递员信息",
    qxzsmsj: "请选择上门时间",
    xj: "新建",
    wssdbl: "未搜索到病例",
    knczyy: "可能存在以下原因",
    wzqsrxm: "未输入正确的患者姓名或者病例号",
    bsydqjg: "搜索的患者不属于当前机构",
    xdddz: "下单等待中，请勿关闭页面",
    nzshmyyxdmx: "您暂时还没有已下单模型哦",
    nzshmywlxx: "您暂时还没有物流信息哦"
  },
  notice: {
    threeD: {
      gxnr: "更新公告",
      dhxg: "3D动画修改",
      yskdsjfa: "医生可对3D设计方案的目标位，附件，去釉间隙和整体牙列进行修改",
      xgnrhxgyj:
        "修改内容和修改意见会反馈给方案设计中心，进一步提升3D设计方案沟通的准确性和效率",
      djyctjxgyj: "点击右侧提交修改意见，再次点击3D动画修改",
      jtdhxggnjs: "具体3D动画修改功能介绍，可查看",
      dhxgczbz: "3D动画修改操作帮助",
      bjsz: "3D背景设置",
      yskytjdh: "医生可以调节3D动画页面主题，选择3D动画背景颜色等",
      xyg: "下一个",
      msty: "马上体验",
      syg: "上一个"
    },
    caseDetail: {
      blzx: "病例咨询",
      xzblzx: "新增病例咨询",
      blzxnr:
        "旨在帮助医生快速针对患者情况进行专业的临床分析，医生在提交病例咨询后，方案团队会第一时间针对咨询进行回复，协助医生对患者进行临床诊断。",
      blzxzr: "病例咨询可直接转病例进行提交",
      gxnr: "更新内容",
      jdtz: "阶段调整",
      xzjdtz: "新增阶段调整",
      jdtznr:
        "阶段调整是指患者矫治过程治疗计划不变，中途需要调整附件位置/乳牙脱落/冠修复等引起的需要重新生产矫治器，需再次提交硅橡胶印模/口扫模型，按原方案重新设计3D方案",
      kck: "可查看",
      help: "如何区分重启和阶段调整",
      zd: "知道了"
    }
  },
  stageAdjust: {
    jdtz: "阶段调整",
    dqjzqthqk: "当前矫治器贴合情况",
    pdyj:
      "判断依据：牙齿移动及咬合关系与模拟矫治设计基本符合，矫治器与牙齿之间无明显空隙。",
    lcjc: "临床检查",
    bcfkspdbs: "本次反馈时佩戴矫治器步数",
    shd: "上颌第",
    xhd: "下颌第",
    bs: "步",
    sjdsjdbs: `（上阶段设计的矫治器步数为1-{totalStep}步，{stepDate} 发货 {stepSection} 步）`,
    fjtz: "附件调整",
    fjknhtz: "附件可能会调整",
    gjsjfakntz: "根据设计方案可能调整其他附件或添加新附件",
    gjsjfakntj: "根据设计方案可能添加新附件",
    yljx: "牙列间隙",
    qjlfk: "请记录反馈1.0mm以下的间隙量",
    qtjc: "其他检查（包括牙齿错位、松动度、早接触度等）",
    qygjddsjyq: "前一个阶段的设计要求",
    rybbgqysjstz:
      "如有变更请医生及时调整，如不修改公司将延续前一个阶段的设计要求",
    bkydyc: "不可移动牙齿",
    kcyxftt: "烤瓷牙，修复桥体，种植牙等无法移动牙齿",
    bksjfjyc: "不可设计附件牙齿",
    kcyxfqtwfzt: "烤瓷牙，修复桥体等无法粘结附件",
    hxjzyq: "后续矫治要求",
    bygbcsjzmb: "不应改变初始矫治目标",
    pdjzqbthbwzp: "佩戴矫治器不贴合部位照片",
    kqnzp: "面像及口腔内照片",
    yhmx: "牙颌模型",
    nxhgjsfyc: "颞下颌关节是否存在异常",
    bthbwzp: "不贴合部位照片",
    jjytmx: "急救牙套模型",
    fasjmx: "方案设计模型",
    qtmx: "其他模型",
    jdtzmx: "阶段调整模型",
    wcblmx: "完成病例模型",
    mxtips: "当前病例存在多种待邮寄硅橡胶模型，请选择需要添加的硅橡胶模型类型",
    qx: "取消",
    qd: "确定"
  },
  threeD: {
    picName: {
      sideFacePicture: "侧面像",
      frontFacePicture: "正面像",
      smilePicture: "微笑像",
      anteriorOverjetPicture: "前牙覆盖像",
      upToothPicture: "上牙列像",
      downToothPicture: "下牙列像",
      mouthRightPicture: "口内右侧位像",
      mouthFrontPicture: "口内正位像",
      mouthLeftPicture: "口内左侧位像",
      xrayCurvePicture: "全颌曲面断层片",
      xraySidePicture: "头颅侧位定位片",

      sideProfile: "侧面像",
      frontal: "正面像",
      frontalSmile: "微笑像",
      upperArch: "上牙列像",
      anteriorOverjet: "前牙覆盖像",
      lowerArch: "下牙列像",
      intraOralRight: "口内右侧位像",
      intraOralFrontal: "口内正位像",
      intraOralLeft: "口内左侧位像",
      panoramicRadiograph: "全颌曲面断层片",
      lateralCephalogram: "头颅侧位定位片"
    },
    czdbandfa: "1.存在对比按钮的方案可以点击方案查看对比",
    dbzqfaja: "2.对比早期方案加载时间稍长，请耐心等待",
    db: "对比",
    gbdhxg: "关闭动画修改",
    ckdhxg: "查看动画修改",
    threeddhxg: "3D动画修改",
    mpczbczczlb: "膜片材质不存在于材质列表",
    // rjjzdjl: '软件将自动记录3D修改结果, 每2分钟自动保存当前数据',
    rjjzdjl: "软件将自动记录3D修改结果",
    xz: "新增",
    xgjx: "修改",
    qc: "去除",
    jx: "间隙",
    sc: "删除",
    lyqy: "邻牙去釉",
    shyctz: "上颌牙齿调整",
    xhyctz: "下颌牙齿调整",
    yw: "牙位",
    jxs: "经销商端",
    fj: "附件",
    hd: "颌垫",
    fjjx: "矩形",
    ty: "椭圆",
    tx: "梯形",
    byh: "半圆H",
    bys: "半圆S",
    ylj: "压力脊",
    yhdb: "咬合导板",
    ylk: "引力扣",
    to: "至",
    xh: "型号",
    fk: "反馈",
    lsfa: "历史方案",
    jzsm: "矫治说明",
    blzl: "病例资料",
    mbfa: "目标方案",
    mpcz: "膜片材质",
    mnfa: "模拟方案，实际治疗效果可能不同",
    zp: "照片",
    xg: "X光",
    qt: "其他",
    zlb: "治疗表",
    zsd: "诊所端",
    xsd: "销售端",
    csd: "厂商端",
    ysd: "医生端",
    ylkj: "隐速科技",
    zsmytp: "暂时没有图片",
    ntjd:
      "您提交的修改意见将作为正式的设计要求录入系统，方案设计中心将按照您的要求尽快做出设计更改；如您对病例有任何疑问，您可在专属服务群内联系我们，谢谢！",
    cwbtx: "此为必填项",
    qzcsrxgyj: "请在此输入修改意见",
    qzcsrjzfayj: "请在此输入矫治方案修改意见",
    qzcsrxffayj: "请在此输入修复方案修改意见",
    qzsxtyxfajxtj: "请至少选填一种方案进行提交修改意见",
    jzfa: "矫治方案",
    xffa: "修复方案",
    yjms: "意见描述"
  },
  braceReissue: {
    tjsq: "提交申请",
    ytsq: "牙套申请",
    shjl: "审核记录",
    qxzbshzcz: "请选择步数或者材质",
    qxzgxjmx: "请选择硅橡胶模型或者数字模型文件",
    qscszmxwj: "请上传数字模型文件",
    dczsczyts:
      "当存在生产中的牙套时，不支持再次申请，请等待生产完成后再提交新的申请",
    ytsqznsq:
      "牙套申请，只能申请当前方案已经发放的牙套，重启病例不能申请重启前的牙套",
    ckmpzh: "查看膜片组合",
    czdyjmx: "存在待邮寄模型",
    djqwyj: "点击前往邮寄",
    ytxx: "牙套信息",
    qzxmstnxz: "请在下面视图内选择需要申请的牙套以及材质",
    qxzzsshdz: "请选择诊所收货地址",
    mrbxytjyhmx: "默认不需要提交牙颌模型，根据牙套选择采用之前数据进行生产",
    rgxycyxdmx: "如果需要采用新的模型文件进行牙套生产，请选项提交牙颌模型",
    sqyy: "申请原因",
    bfxx: "补发信息",
    sqsj: "申请时间",
    shjg: "审核结果",
    shsm: "审核说明",
    dyjmx: "待邮寄模型",
    yjmx: "邮寄模型",
    zwshjl: "暂无审核记录",
    shz: "审核中",
    tg: "通过",
    btg: "不通过",
    blzzwjsmx: "病例存在未寄送模型",
    fwjzrq: "服务截止日期",
    qqdsfjxgcz: "请确定是否继续该操作？",
    ztfw: "暂停服务",
    ztqrhgjpdsj:
      "患者无法继续使用该产品矫正或其他因素等一些特殊原因，医生需要临时中止服务计时，暂停确认后系统会暂停该病例的服务计时，直到下次继续发货或者重启/阶段调整时继续累加计时",
    mcfhhfkczzt: "每次发货后，方可操作暂停",
    jjyt: "急救牙套",
    gxjmxxz: "硅橡胶模型选择",
    mxxz: "模型选择",
    jjytmx: "急救牙套模型",
    fjjytmx: "非急救牙套模型",
    dqblczjjyadyj:
      "当前病例存在急救牙套待邮寄硅橡胶模型，请选择需要添加的硅橡胶模型类型",
    qxzmxlx: "请选择模型类型",
    dzxz: "地址选择",
    tj: "提交",
    btj: "不提交",
    shb: "上颌（步）",
    xhb: "下颌（步）",
    mpzh: "膜片组合",
    cxtj: "重新提交",
    czxz: "材质选择",
    qx: "取消",
    qd: "确定"
  },
  yssh: {
    tjgjjzq: "提交硅胶矫治器",
    tjhdjzq: "提交活动矫治器",
    tjyxjzq: "提交隐形矫治器",
    fadqcyirdt:
      "方案当前处于iRDT端，需要等待iRDT通过后医生才能批准或者提交修改意见",
    xytjjzqcfb: "需要提交硅胶矫治器处方表",
    xytjhdcfb: "需要提交活动矫治器处方表",
    xytjyxcfb: "需要提交隐形矫治器处方表",
    yssh: "医生收货",
    dzxz: "地址选择",
    ysshdz: "医生收货地址",
    djdzxz: "点击“地址选择”选取医生收货地址",
    spjzqshdz: "上批矫治器收货地址",
    tjdz: "添加地址",
    nzwtjrhshdz: "您暂未添加任何收货地址",
    qxzzsshdz: "请选择诊所收货地址",
    qxzysshdz: "请选择医生收货地址",
    ysdzgl: "医生地址管理",
    xzdz: "新建地址",
    bjdz: "编辑地址",
    sfmr: "是否默认",
    smqj: "上门取件",
    zxyj: "自行邮寄",
    bdsc: "本地上传",
    itero: "iTero",
    qt: "其他"
  },
  //病例咨询
  blzx: {
    hzxm: "患者姓名",
    sex: "性别",
    birthday: "出生日期",
    zs: "主诉",
    mxplaceholder: "描述请限制在2000字内（误删除可使用“ctrl＋z”撤销误操作）",
    by: "是否接受拔牙",
    Y: "是",
    N: "否",
    zkd: "是否配合种植支抗钉",
    zyzxwt: "主要咨询问题",
    mxpic: "面像及口腔内照片",
    psbz: "照片拍摄标准",
    tz: "可以从电脑拖拽图片",
    xpic: "X光片",
    yhmx: "牙颌模型",
    fazxb: "病例咨询表",
    yltj: "预览及提交",
    bltxwz: "病例咨询已填写完成，请确认病例咨询内容",
    xjblzx: "新建病例咨询",
    all: "全部",
    inService: "服务中",
    toEvaluated: "待评价",
    consultationId: "咨询订单编号",
    hzzl: "患者资料",
    age: "岁",
    nn: "男",
    nv: "女",
    createDate: "创建日期",
    Operation: "操作",
    ck: "查看",
    del: "删除",
    toSub: "待提交",
    inPro: "处理中",
    toFeed: "待您反馈",
    toCom: "待您确认",
    closed: "已关闭",
    describe: "描述",
    screenshot: "截图",
    screenshotTip:
      "支持 .png, jpg,PNG,JPG 等格式，每个图片大小不得超过 20M，最多上传 10 个图片",
    attachment: "附件",
    attachmentTip:
      "支持 .png, .jpg, .txt, .rar, .doc 等格式，每个附件大小不得超过20M，最多上传 10 个附件",
    zxhf: "咨询回复",
    djsc: "点击上传",
    tj: "提交",
    gtjl: "沟通记录",
    load: "加载更多",
    iTero: "iTero",
    upload: "本地上传",
    other: "其他",
    sale: "销售",
    doctor: "医生",
    tjTime: "提交时间",
    ddStatus: "订单咨询状态",
    qdbgbzx: "确定并关闭咨询",
    gbzx: "关闭咨询",
    evaluate: "评价",
    turn: "转病例",
    evaluated: "已评价",
    toClo: "待关闭",
    toAcc: "待受理",
    toEva: "待评价",
    processed: "已处理",
    accepted: "已受理",
    confirmed: "已确认",
    confirmClose: "确认关闭",
    closeTip: "关闭咨询是不可退回的操作，确定要关闭咨询吗",
    evaluateTip:
      "请对我本次服务做出评价，您的建议对我们的服务质量提升非常重要。",
    bmy: "非常不满意",
    my: "非常满意",
    overall: "本次服务总体体验",
    isHelp: "病例咨询服务（是否对您有帮助）",
    speed: "咨询回复速度",
    attitude: "咨询服务态度",
    qr: "确认",
    product: "产品",
    selProduct: "请选择产品",
    clinic: "诊所",
    selClinic: "请选择诊所",
    selBind: "请选择套餐绑定产品",
    bindProduct: "套餐绑定产品",
    idRdtHelp: "是否iRDT协助",
    need: "需要",
    noNeed: "不需要",
    selRdt:'请选择Rdt',
  },
  quillEditor: {
    placeholder: "请输入",
    img: "图片"
  }
};
