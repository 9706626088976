<template>
  <!--删除了banner-->
  <!--<div :class="{header_home: $route.path === '/home'}" class="header">-->
  <div class="header">
    <div class="head_con">
      <img
        alt=""
        class="logo curp"
        src="../../common/imgs/home/logo.png"
        @click="goPage('/home')"
      />
      <ul class="nav" @click="selectedMenu">
        <li
          :class="
            $route.path === '/' || $route.path.substring(0, 5) === '/home'
              ? 'border li_active'
              : ''
          "
          data-url="/"
        >
          {{ $t("common.header.zy") }}
        </li>
        <li
          :class="
            $route.path.substring(0, 6) === '/cases' ? 'border li_active' : ''
          "
          data-url="/cases?type=to_handle"
        >
          {{ $t("common.header.bl") }}
        </li>
        <li
          :class="
            $route.path.substring(0, 13) === '/consultation'
              ? 'border li_active'
              : ''
          "
          data-url="/consultation"
        >
          {{ $t("common.header.blzx") }}
        </li>
        <li
          :class="
            $route.path.substring(0, 11) === '/helpCenter'
              ? 'border li_active'
              : ''
          "
          data-url="/helpCenter"
        >
          {{ $t("common.header.bzzx") }}
        </li>
        <li class="h_right">
          <input
            v-model="conditionForQuery"
            :placeholder="$t('common.header.ssts')"
            type="text"
            @keyup.enter="toSearchCases"
          />
          <span class="search_icon" @click="toSearchCases">
            <img alt="" src="/static/images/header/web_doc_search.png" />
          </span>
        </li>
        <li class="head_top_con_r">
          <div class="top_c_r_left">
            <span
              :class="lang === 'zh_CN' ? 'r_left_icon01' : 'r_left__icon01'"
              class="r_left_icon"
              @click="changeLanguage"
            ></span>
            <div class="icon02_box mr20 ml20 curp">
              <img
                alt=""
                class="header_message"
                src="../../common/imgs/home/header_message.png"
                @click="goPage('/home/moreHomePage')"
              />
              <!--<span class="r_left_icon02"></span>-->
              <div
                v-if="msgList.total"
                :class="{
                  expressage_header_tabs_total: +msgList.total < 10,
                  expressage_header_tabs_total_two:
                    +msgList.total <= 99 && +msgList.total > 9,
                  expressage_header_tabs_total_three: +msgList.total > 99
                }"
                class="expressage_header_tabs_total_main tag_b flex-x-y-c"
              >
                {{ +msgList.total > 99 ? "99+" : msgList.total }}
              </div>
              <!--<div class="" >-->
              <!--{{msgList.total || ''}}-->
              <!--</div>-->
              <ul
                :class="{ 'flex-x-y-c': msgList.list.length < 1 }"
                class="mg_box"
                @click="msgOperation"
              >
                <template v-if="msgList.list.length">
                  <li
                    v-for="item in msgList.list"
                    :key="item.id"
                    class="msg_single"
                  >
                    <div
                      :data-caseId="item.caseId"
                      :data-caseid="item.caseId"
                      :data-cureNoteId="item.refCode"
                      :data-cureType="item.cureType"
                      :data-cureplanid="item.curePlanId"
                      :data-id="item.id"
                      :data-note="item.node"
                      :data-status="item.status"
                      class="click-mok"
                    ></div>
                    <p class="msg_content">{{ item.content }}</p>
                    <div class="msg_u_box">
                      <div class="u_b_left">
                        <p v-if="item.fromUserInfo" class="u__l_pic">
                          <img
                            :src="
                              item.fromUserInfo.photo
                                ? $PicPrefix + item.fromUserInfo.photo
                                : defPhoto
                            "
                          />
                        </p>
                        <div class="u__l_r">
                          <h5 class="u__l_r_h5">
                            {{
                              item.fromUserInfo
                                ? `${
                                    item.fromUserInfo.realName
                                      ? item.fromUserInfo.realName
                                      : "-"
                                  }${
                                    item.fromUserInfo.mobile
                                      ? `_${item.fromUserInfo.mobile}`
                                      : ""
                                  }`
                                : ""
                            }}
                          </h5>
                          <p class="u__l_r_p">{{ item.createDatetime }}</p>
                        </div>
                      </div>
                      <div class="u_b_right">
                        {{
                          noteObj[item.node]
                            ? noteObj[item.node].text
                            : $t("common.header.wzdl")
                        }}
                        <img
                          v-if="item.type !== 'case_chat'"
                          alt=""
                          src="../../common/imgs/home/message_icon.png"
                        />
                      </div>
                    </div>
                  </li>
                  <li class="w100 flex-x-y-c" style="height: 0.68rem">
                    <div
                      v-if="msgList.list.length"
                      class="look_all_btn flex-x-y-c curp fz14"
                      @click="goPage('/home/moreHomePage')"
                    >
                      {{ $t("common.header.ckqb") }}
                    </div>
                  </li>
                </template>
                <template v-else>
                  <div
                    class="not_message"
                    style="
                      display: flex;
                      flex-flow: column;
                      align-items: center;
                    "
                  >
                    <img
                      alt=""
                      src="../../common/imgs/home/not_message.png"
                      style="display: block; width: 142px; height: 90px"
                    />
                    <p class="mt12 fz14 main_theme_color_666">
                      {{ $t("home.home.nzshmyxxx") }}
                    </p>
                  </div>
                </template>
                <li class="top_arrows"></li>
              </ul>
            </div>
          </div>
          <div
            class="top_c_r_right"
            @mouseleave="showDropDown = false"
            @mouseover="showDropDown = true"
            @click.stop="goPage('/home/personal')"
          >
            <img
              :src="`${userPhoto ? $PicPrefix + userPhoto : defPhoto}`"
              alt=""
              class="user-avatar"
            />
            <div v-show="showDropDown" class="drop_down" @click.stop>
              <p class="top_arrows_user"></p>
              <div>
                {{ "loginName" in userMsg ? userMsg.loginName : "" }}
              </div>
              <div class="user_wrap" @click="goPage('/home/personal')">
                <div class="icon_user"></div>
                {{ $t("home.personal.personal.grzx") }}
              </div>
              <div
                class="preference_wrap"
                @click="goPage('/cases/clinicalPreferences')"
              >
                <div class="icon_preference"></div>
                {{ $t("home.personal.personal.lcbl") }}
              </div>
              <div class="out_wrap" @click.stop="loginOut">
                <div class="icon_out"></div>
                {{ $t("common.header.tcdl") }}
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div v-if="showTour" class="tour-wrap">
      <div class="step-current">
        <div class="icon_preference"></div>
        {{ $t("home.personal.personal.lcbl") }}
      </div>
      <div class="tour-container">
        <img
          alt=""
          class="close-tour"
          src="../../common/imgs/home/close.png"
          @click="setLocalClinicalRrefReminder()"
        />
        <div class="tour-header"></div>
        <div class="tour-content">
          <div>
            <div class="step-title">
              {{ $t("home.personal.personal.lcbl") }}
            </div>
            <div class="step-content">
              {{ $t("home.personal.personal.lcphtx") }}
            </div>
          </div>
          <div class="step-footer">
            <div class="no-reminder" @click="noReminder">
              {{ $t("home.personal.personal.bztx") }}
            </div>
            <div class="to-set" @click="toSetClinic">
              {{ $t("home.personal.personal.szlcph") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { caseChatHandle, casesDetail } from "common/api/cases";
import { getUser, closeClinicalPrefReminder } from "common/api/user";
import { defPhoto } from "common/js/requireImage";
import { clearUser, isLogin, getUserId, removeCreate } from "common/js/util";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import LangStorage from "common/js/cookie";
import { getCityList } from "common/api/public";
import $ from "jquery";
import { notifyMsg } from "common/js/util";
import { extend } from "lodash";

export default {
  data() {
    return {
      defPhoto,
      pathUrl: "",
      qiniuDomain: "",
      showDropDown: false,
      showTour: false,
      userMsg: {},
      userPhoto: "",
      conditionForQuery: sessionStorage.getItem("conditionForQuery") || "",
      noteObj: {
        to_improve_plan: {
          text: this.$t("common.header.qtj"),
          href: "/cases/create"
        },
        to_data_approve: {
          text: this.$t("common.header.qtj"),
          href: "/cases/create"
        },
        to_submit: {
          text: this.$t("common.header.qtj"),
          href: "/cases/create"
        },
        to_text_plan: {
          text: this.$t("common.header.qpz"),
          href: "/cases/reviewTextPlan?schemeType=target"
        },
        to_sign: {
          text: this.$t("common.header.qpz"),
          href: "/cases/view3d?schemeType=cure"
        },
        to_doctor_confirm_plan: {
          text: this.$t("common.header.qpz"),
          href: "/cases/view3d?schemeType=cure"
        },
        reject: {
          text: this.$t("common.header.ckyy"),
          isModal: true
        },
        to_receive: {
          text: this.$t("casesDetail.casesDetail.jghx"),
          href: "/cases/detail?delivery=1"
        },
        to_approve_plan: {
          text: this.$t("common.header.qck"),
          href: "/cases/view3d",
          type: "cure"
        },
        to_check_order: {
          text: this.$t("common.header.qtj"),
          href: "/cases/create"
        },
        to_recheck_order: {
          text: this.$t("common.header.qtj"),
          href: "/cases/create"
        },
        to_scan_y: {
          text: this.$t("common.header.qtj"),
          href: "/cases/create"
        },
        to_rescan_y: {
          text: this.$t("common.header.qtj"),
          href: "/cases/create"
        },
        to_done: {
          text: this.$t("common.header.qtj"),
          href: "/cases/maintain"
        }
      },
      lang: localStorage.getItem("user_lang") || "zh_CN",
      modifyPhoto: sessionStorage.getItem("modifyPhoto")
    };
  },
  computed: {
    ...mapGetters({
      msgList: "getMsgList" //病例详情
    }),
    ...mapState({
      menuUrl: state => state.menuUrl,
      isLogin: state => state.isLogin,
      isHandleMsg: state => state.isHandleMsg,
      isModifyUserPhoto: state => state.isModifyUserPhoto
    }),
    isShowHead() {
      let str = this.$route.path;
      return str.substring(0, 5) === "/case" || str.substring(0, 5) === "/help";
    }
  },
  methods: {
    ...mapActions({
      getMsgList: "actionGetMsgList" //病例详情
    }),
    changeLanguage() {
      const obj = {
        zh_CN: "en_US",
        en_US: "zh_CN"
      };
      this.lang = obj[this.lang];
      this.$i18n.locale = this.lang;
      LangStorage.setLang(this.$i18n.locale);
      sessionStorage.removeItem("cityData");
      window.location.reload();
    },
    caseMessagePage() {
      this.getMsgList({
        toUserId: getUserId(),
        status: "to_handle"
      });
    },
    msgOperation(ev) {
      const target = ev.target;
      const note = target.getAttribute("data-note") || "";
      const status = target.getAttribute("data-status") || "";
      const id = target.getAttribute("data-id") || "";
      const cureNoteId = target.getAttribute("data-cureNoteId") || "";
      const caseId = target.getAttribute("data-caseId" || "");
      const cureType = target.getAttribute("data-cureType" || "");
      if (id) {
        if (!note) {
          this.isLoading = true;
          caseChatHandle(id)
            .then(() => {
              const msgLi = $(target).parents(".msg_li");
              msgLi.animate(
                {
                  marginLeft: "-300px",
                  opacity: "0"
                },
                500
              );
              const pathname = window.location.pathname;
              if (pathname === "/home/moreHomePage") {
                setTimeout(() => {
                  msgLi.animate(
                    {
                      marginLeft: "0",
                      opacity: "1"
                    },
                    500
                  );
                }, 16);
              }
              this.isLoading = false;
              this.HANDLE_MSG();
            })
            .catch(() => {
              this.isLoading = false;
            });
        } else {
          const caseId = target.getAttribute("data-caseid") || "";
          const curePlanId = target.getAttribute("data-cureplanid") || "";
          // const href = this.noteObj[note].href;

          let query = `?caseId=${caseId}&curePlanId=${curePlanId || ""}`;

          if (["reissue"].includes(note)) {
            caseChatHandle(id)
              .then(() => {
                const msgLi = $(target).parents(".msg_li");
                msgLi.animate(
                  {
                    marginLeft: "-300px",
                    opacity: "0"
                  },
                  500
                );
                const pathname = window.location.pathname;
                if (pathname === "/home/moreHomePage") {
                  setTimeout(() => {
                    msgLi.animate(
                      {
                        marginLeft: "0",
                        opacity: "1"
                      },
                      500
                    );
                  }, 16);
                }
                this.isLoading = false;
                this.HANDLE_MSG();
                setTimeout(() => {
                  window.open("/cases/bracesReissue" + query + "&tabIdx=1");
                }, 1000);
              })
              .catch(() => {
                this.isLoading = false;
              });
          }

          if (
            ["to_sign", "to_doctor_confirm_plan", "to_approve_plan"].includes(
              note
            )
          ) {
            casesDetail(caseId).then(res => {
              const { cureType } = { ...res };
              if (cureType === "F") {
                window.open(
                  `/exocad?schemeType=cure&cureNoteId=${cureNoteId}&caseId=${caseId}&curePlanId=${curePlanId ||
                    ""}`
                );
              } else {
                window.open(
                  `/cases/view3d?schemeType=cure&cureNoteId=${cureNoteId}&caseId=${caseId}&curePlanId=${curePlanId ||
                    ""}`
                );
              }
            });
          }
          if (note === "to_text_plan") {
            window.open(
              `/cases/view3d?schemeType=target&cureNoteId=${cureNoteId}&` +
                query
            );
          }
          if (note === "to_receive") {
            window.open(
              "/cases/detail?delivery=1&" +
                `caseId=${caseId}&curePlanId=${curePlanId || ""}`
            );
          }
          if (
            [
              "to_submit",
              "to_data_approve",
              "precuring",
              "to_scan",
              "to_agent_scan",
              "to_check_order",
              "to_recheck_order",
              "to_scan_y",
              "to_rescan_y",
              "to_improve_plan"
            ].includes(note)
          ) {
            casesDetail(caseId).then(res => {
              const purePlanInfo = res["curePlanInfo"];
              if (purePlanInfo.phaseAdjustmentNumber !== 0) {
                window.open(`/stage-adjustment` + query);
              } else {
                const href = "/cases/create?&";
                window.open(
                  href + `caseId=${caseId}&curePlanId=${curePlanId}`,
                  "_blank"
                );
              }
            });
          }
          if (note === "to_draw_line" && status === "to_handle") {
            window.open(
              "/cases/maintain?" + `caseId=${caseId}&curePlanId=${curePlanId}`,
              "_blank"
            );
          }
          if (note === "reject") {
            this.whyObj.tipText = splitText(item.contentDetail);
            this.removeId = id;
            this.isShowWhy = true;
            this.listIndex = index;
          }
        }
      }
    },
    toSearchCases() {
      if (!this.conditionForQuery) {
        sessionStorage.removeItem("conditionForQuery");
      } else {
        sessionStorage.setItem("conditionForQuery", this.conditionForQuery);
      }
      this.HEAD_SEARCH(this.conditionForQuery);
      this.goPage("/cases");
    },
    selectedMenu(ev) {
      this.conditionForQuery = "";
      const target = ev.target;
      const URL = target.getAttribute("data-url");
      if (!URL) return;
      sessionStorage.setItem("menuUrl", URL);
      sessionStorage.removeItem("selectedKey");
      sessionStorage.removeItem("conditionForQuery");
      removeCreate();
      let route = this.$route;
      if (URL === "/helpCenter") {
        this.$router.replace({
          name: "helpCenter",
          query: extend({}, { type: "14" })
        });
      } else {
        this.$router.replace({ path: URL });
      }
      this.GET_MENU_URL(URL);
    },
    loginOut() {
      setTimeout(() => {
        clearUser();
        this.$router.push("/login");
      }, 200);
    },
    goPage(url) {
      if (url) {
        this.$router.push({
          path: url
        });
      }
    },
    setLocalClinicalRrefReminder() {
      localStorage.setItem("clinicalPrefReminder", "0");
      this.showDropDown = false;
      this.showTour = false;
    },
    noReminder() {
      closeClinicalPrefReminder().then(() => {
        notifyMsg(
          this,
          "success",
          this.$t("casesDetail.casesChildren.common.czcg")
        );
        this.setLocalClinicalRrefReminder();
      });
    },
    toSetClinic() {
      this.goPage("/cases/clinicalPreferences");
      this.setLocalClinicalRrefReminder();
    },
    getUserInfo() {
      getUser().then(data => {
        this.userMsg = data;
        this.userPhoto = data["photo"];
        const localClinicalReminder = localStorage.getItem(
          "clinicalPrefReminder"
        );
        if (localClinicalReminder !== null) {
          if (localClinicalReminder === "0") return;
          if (data.clinicalPrefReminder === "1") {
            this.showDropDown = true;
            this.showTour = true;
          }
        } else {
          if (data.clinicalPrefReminder === "1") {
            localStorage.setItem(
              "clinicalPrefReminder",
              data.clinicalPrefReminder
            );
            this.showDropDown = true;
            this.showTour = true;
          }
        }
      });
    },
    ...mapMutations([
      "GET_LOGIN_STATUS",
      "GET_MENU_URL",
      "HANDLE_MSG",
      "HEAD_SEARCH"
    ]),
    ...mapActions(["getUserMsg"])
  },
  created() {
    this.pathUrl = window.location.pathname;
    const URL = sessionStorage.getItem("menuUrl") || "";
    if (URL) {
      this.GET_MENU_URL(URL);
    }
    this.caseMessagePage();
    const cityData = sessionStorage.getItem("cityData") || "";
    if (!cityData) {
      getCityList();
    }
  },
  mounted() {
    if (isLogin()) {
      this.getUserInfo();
    }
  },
  watch: {
    userMsg: {
      handler() {
        this.qiniuDomain = window.QINIU_DOMAIN;
      },
      deep: true
    },
    isModifyUserPhoto() {
      this.getUserInfo();
    },
    isHandleMsg() {
      this.caseMessagePage();
    }
  }
};
</script>

<style lang="scss" scoped>
.tour-wrap {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.2);
  z-index: 1001;

  .step-current {
    width: 182px;
    height: 52px;
    position: absolute;
    background-color: #fff;
    top: 170px;
    right: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.01rem;
    font-size: 14px;

    .icon_preference {
      width: 18px;
      height: 18px;
      background-image: url("../../common/imgs/home/icon_preference.png");
      background-size: 100% 100%;
      margin-right: 8px;
    }
  }

  .tour-container {
    position: absolute;
    width: 404px;
    height: 368px;
    right: 360px;
    top: 105px;
    display: flex;
    flex-direction: column;

    .close-tour {
      cursor: pointer;
      width: 24px;
      height: 24px;
      transform: translateX(50%);
      position: absolute;
      right: 0;
      top: 0;
      z-index: 12;
    }

    .tour-header {
      width: 414px;
      height: 141px;
      z-index: 10;
      background-image: url("../../common/imgs/home/tour-header.png");
      background-size: 100% 100%;
    }

    .tour-content {
      flex: 1;
      transform: translateY(-20px);
      border-radius: 0 0 12px 12px;
      background-color: #ffffff;
      padding: 16px;
      display: flex;
      flex-direction: column;
      align-items: space-between;
      justify-content: space-between;

      .step-title {
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        margin: 12px 0 17px;
        color: #333;
      }

      .step-content {
        font-size: 14px;
        line-height: 20px;
        color: #333;
      }

      .step-footer {
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .no-reminder {
          cursor: pointer;
          font-size: 14px;
          line-height: 20px;
          color: #fcc80e;
        }

        .to-set {
          cursor: pointer;
          min-width: 124px;
          padding: 0 20px;
          height: 36px;
          border-radius: 4px;
          background-color: #fcc80e;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}

.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  background-color: #555657;

  .head_con {
    width: 13.44rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 0.62rem;

    .logo {
      display: block;
      width: 2rem !important;
    }

    .nav {
      display: flex;
      color: white;
      align-items: center;

      .top_c_r_right {
        width: 32px;
        height: 32px;
        font-size: 0.12rem;
        position: relative;
        cursor: pointer;

        .user-avatar {
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }

        .drop_down {
          width: 182px;
          height: 227px;
          position: absolute;
          bottom: -0.1rem;
          left: -0.7rem;
          transition: all 0.3s;
          transform: translateY(100%);
          letter-spacing: 0.01rem;
          background-color: #fff;
          border-radius: 0.04rem;
          color: $main_theme_color_333;
          cursor: pointer;
          text-align: center;
          padding-top: 0.1rem;
          font-size: 0.14rem;

          & > div:nth-child(1) {
            height: 42px;
          }

          div:hover {
            background: #f9f9f9;
            transition: all 0.2s;
            color: $main_theme_color;
          }

          div {
            height: 52px;
            transition: all 0.2s;
            display: flex;
            align-items: center;
            justify-content: center;

            > div {
              width: 16px;
              height: 16px;
              background-size: 100% 100%;
            }

            .icon_user {
              background-image: url("../../common/imgs/home/icon_user.png");
              margin-right: 8px;
            }

            .icon_preference {
              background-image: url("../../common/imgs/home/icon_preference.png");
              margin-right: 8px;
            }

            .icon_out {
              background-image: url("../../common/imgs/home/icon_out.png");
              margin-right: 8px;
            }
          }

          .user_wrap:hover .icon_user {
            background-image: url("../../common/imgs/home/icon_uesr_y.png") !important;
          }

          .preference_wrap:hover .icon_preference {
            background-image: url("../../common/imgs/home/icon_preference_y.png");
          }

          .out_wrap:hover .icon_out {
            background-image: url("../../common/imgs/home/icon_out_y.png");
          }

          .top_arrows_user {
            display: block;
            width: 0.2rem;
            height: 0.1rem;
            background: url("/static/images/header/jiantou.png");
            position: absolute;
            left: 0.76rem;
            top: -0.09rem;
          }

          &:hover {
            opacity: 1;
          }

          i {
            display: inline-block;
            width: 0.1rem;
            height: 0.1rem;
            position: absolute;
            top: -4%;
            left: 40%;
            background-color: #fff;
            transform: rotate(45deg);
            z-index: 1;
          }
        }
      }

      & > li:nth-child(1) {
        padding: 0 0.18rem 0;
        font-size: 0.2rem;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        margin-right: 20px;

        &.border::after {
          content: "";
          width: 100%;
          height: 0.06rem;
          background-color: $main_theme_color;
          position: absolute;
          top: -0.22rem;
          left: 0;
        }
      }

      & > li:nth-child(2) {
        padding: 0 0.18rem 0;
        font-size: 0.2rem;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        margin-right: 20px;

        &.border::after {
          content: "";
          width: 100%;
          height: 0.06rem;
          background-color: $main_theme_color;
          position: absolute;
          top: -0.22rem;
          left: 0;
        }
      }

      & > li:nth-child(3) {
        padding: 0 0.18rem 0;
        font-size: 0.2rem;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;

        &.border::after {
          content: "";
          width: 100%;
          height: 0.06rem;
          background-color: $main_theme_color;
          position: absolute;
          top: -0.22rem;
          left: 0;
        }
      }

      & > li:nth-child(4) {
        padding: 0 0.18rem 0;
        font-size: 0.2rem;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;

        &.border::after {
          content: "";
          width: 100%;
          height: 0.06rem;
          background-color: $main_theme_color;
          position: absolute;
          top: -0.22rem;
          left: 0;
        }
      }

      .li_active {
        color: $main_theme_color;
      }

      .h_right {
        height: 0.34rem;
        width: 3.18rem;
        line-height: 0.38rem;
        background-color: white;
        color: $main_theme_color_333;
        display: flex;
        padding: 0 0.1rem 0 0.13rem;
        border-radius: 0.04rem;
        align-items: center;
        margin-left: 1.82rem;
        position: relative;

        input {
          padding-right: 20%;
          line-height: 0.38rem;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          flex: 1;
        }

        .search_icon {
          display: flex;
          border-radius: 0.05rem;
          background-color: $main_theme_color;
          width: 0.4rem;
          height: 100%;
          background-size: 100% 100%;
          cursor: pointer;
          position: absolute;
          right: 0;
          justify-content: center;
          align-items: center;

          img {
            display: block;
            width: 0.17rem;
            height: 0.17rem;
          }
        }
      }

      .head_top_con_r {
        display: flex;
        align-items: center;
        margin-left: 0.4rem;

        .top_c_r_left {
          display: flex;
          align-items: center;

          span {
            display: inline-block;
            width: 0.32rem;
            height: 0.32rem;
            background-size: 100% 100%;
            border-radius: 100%;
          }

          .r_left_icon01 {
            cursor: pointer;
            width: 0.32rem;
            height: 0.32rem;
            background-image: url("../../common/imgs/home/header_yw.png");
          }

          .r_left__icon01 {
            width: 0.32rem;
            height: 0.32rem;
            cursor: pointer;
            background-image: url("../../common/imgs/home/header_zw.png");
          }

          .icon02_box {
            width: 0.32rem;
            position: relative;
            height: 0.62rem;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 32px;
              height: 32px;
            }

            &:hover .mg_box {
              opacity: 1;
              transition: all 0.2s;
              display: inline-block;
            }

            &:hover .not_message {
              margin-top: 100px;
            }

            .tag_b {
              position: absolute;
              right: 0.1rem;
              top: 0.15rem;
              color: white;
              font-size: 0.12rem;
              display: flex;
              align-items: center;
              justify-content: center;
              transform: translateX(80%);
            }
          }

          .mg_box {
            transition: all 0.2s;
            position: absolute;
            width: 4.4rem;
            height: 374px;
            background-color: #fff;
            bottom: 0;
            left: -3.6rem;
            transform: translateY(100%);
            padding: 0.14rem 0 0;
            color: #333;
            font-size: 0.16rem;
            border-radius: 0.05rem;
            box-shadow: 0 0 0.06rem 0 rgba(164, 164, 164, 0.28);
            display: none;

            .top_arrows {
              display: block;
              width: 0.2rem;
              height: 0.1rem;
              background: url("/static/images/header/jiantou.png");
              position: absolute;
              right: 0.53rem;
              top: -0.09rem;
            }

            .msg_single {
              padding: 16px 20px;
              height: 102px;
              border-bottom: 0.01rem solid #e5e5e5;
              box-sizing: border-box;
              position: relative;

              .click-mok {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 9;
                cursor: pointer;
              }

              .msg_content {
                line-height: 0.22rem;
                color: $main_theme_color_333;
                font-size: 0.14rem;
              }

              .msg_u_box {
                margin-top: 14px;
                display: flex;
                align-items: center;
                justify-content: space-between;
              }

              .u_b_left {
                display: flex;
                align-items: center;

                .u__l_pic {
                  min-width: 0.44rem;
                  width: 0.44rem;
                  height: 0.44rem;
                  border-radius: 100%;
                  margin-right: 0.12rem;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }

                .u__l_r_p {
                  color: $main_theme_color_999;
                  font-size: 0.12rem;
                }
              }

              .u_b_right {
                color: $main_theme_color;
                cursor: pointer;
                font-size: 14px;
                display: flex;
                align-items: center;

                img {
                  display: block;
                  width: 14px;
                  height: 14px;
                  margin-left: 4px;
                }
              }
            }

            .look_all_btn {
              width: 96px;
              height: 36px;
              background: #fcc80e;
              border-radius: 4px;
            }
          }

          .more_box {
            height: 0.48rem;
            line-height: 0.48rem;
            text-align: center;
            cursor: pointer;
            color: $main_theme_color;
            background-color: #f2f2f2;
            box-shadow: 0 0 0.1rem 0 rgba(164, 164, 164, 0.28);
          }
        }
      }
    }
  }
}

.header_home {
  top: 90px !important;
}

.expressage_header_tabs_total_main {
  height: 14px;
  background: #ed4027;
  border-radius: 7px;
  font-size: 12px;
  color: white;
  padding-left: 4px;
  text-align: center;
}

.expressage_header_tabs_total {
  width: 14px;
}

.expressage_header_tabs_total_two {
  width: 22px;
}

.expressage_header_tabs_total_three {
  width: 28px;
}
</style>
