import {getUser} from 'common/api/user';
import {formatDate} from 'common/js/util';

export default {
  getUserMsg(context){
    getUser().then(data => {
      const obj = {
        parentId: data.parentId,
        kind: data.kind,
        nickname: data.nickname,
        loginName: data.loginName,
        realName: data.realName,
        photo: data.photo,
        mobile: data.mobile,
        email: data.email,
        wechatCode: data.wechatCode,
        wechatQrCode: data.wechatQrCode,
        description: data.description,
        isEmail: data.emailBindFlag,
        isPhone: data.mobileBindFlag,
        isKind: !!data.idNo,
        loginPwdFlag: data.loginPwdStrength,
        tradepwdFlag: data.tradePwdStrength,
        googleAuthFlag: data.googleAuthFlag,
        createDatetime: formatDate(data.createDatetime, 'yyyy-MM-dd hh:mm:ss'),
        lastLogin: formatDate(data.lastLoginDatetime, 'yyyy-MM-dd hh:mm:ss')
      };
      context.commit('USER_MSG', obj);
    });
  }
}
