export const defPhoto = require('../../../public/static/images/default_pic.png');
export const BOY_ICON = require('../../../public/static/images/boy.png');
export const GIRL_ICON = require('../../../public/static/images/girl.png');
export const TEETH_PIC_OBJ = {
  '18': require('../../../public/static/images/teeth/web_doc_tooth18.png'),
  '17': require('../../../public/static/images/teeth/web_doc_tooth17.png'),
  '16': require('../../../public/static/images/teeth/web_doc_tooth16.png'),
  '15': require('../../../public/static/images/teeth/web_doc_tooth15.png'),
  '14': require('../../../public/static/images/teeth/web_doc_tooth14.png'),
  '13': require('../../../public/static/images/teeth/web_doc_tooth13.png'),
  '12': require('../../../public/static/images/teeth/web_doc_tooth12.png'),
  '11': require('../../../public/static/images/teeth/web_doc_tooth11.png'),
  '21': require('../../../public/static/images/teeth/web_doc_tooth21.png'),
  '22': require('../../../public/static/images/teeth/web_doc_tooth22.png'),
  '23': require('../../../public/static/images/teeth/web_doc_tooth23.png'),
  '24': require('../../../public/static/images/teeth/web_doc_tooth24.png'),
  '25': require('../../../public/static/images/teeth/web_doc_tooth25.png'),
  '26': require('../../../public/static/images/teeth/web_doc_tooth26.png'),
  '27': require('../../../public/static/images/teeth/web_doc_tooth27.png'),
  '28': require('../../../public/static/images/teeth/web_doc_tooth28.png'),
  '48': require('../../../public/static/images/teeth/web_doc_tooth48.png'),
  '47': require('../../../public/static/images/teeth/web_doc_tooth47.png'),
  '46': require('../../../public/static/images/teeth/web_doc_tooth46.png'),
  '45': require('../../../public/static/images/teeth/web_doc_tooth45.png'),
  '44': require('../../../public/static/images/teeth/web_doc_tooth44.png'),
  '43': require('../../../public/static/images/teeth/web_doc_tooth43.png'),
  '42': require('../../../public/static/images/teeth/web_doc_tooth42.png'),
  '41': require('../../../public/static/images/teeth/web_doc_tooth41.png'),
  '31': require('../../../public/static/images/teeth/web_doc_tooth31.png'),
  '32': require('../../../public/static/images/teeth/web_doc_tooth32.png'),
  '33': require('../../../public/static/images/teeth/web_doc_tooth33.png'),
  '34': require('../../../public/static/images/teeth/web_doc_tooth34.png'),
  '35': require('../../../public/static/images/teeth/web_doc_tooth35.png'),
  '36': require('../../../public/static/images/teeth/web_doc_tooth36.png'),
  '37': require('../../../public/static/images/teeth/web_doc_tooth37.png'),
  '38': require('../../../public/static/images/teeth/web_doc_tooth38.png'),
};
