import Message from 'components/message/message';
import axios from 'axios';
import { clearUser, goLogin } from 'common/js/util';
import zh_CN from '../lang/zh';
import en_US from '../lang/en';

const ERR_OK = '200';
const ERR_TIME_OUT = '4';

const message = new Message();

export default function fetch(code, param) {
  const url = '/api' + code;
  const data = {
    ...param
  };
  param = JSON.stringify(data);
  let locale = window.localStorage.getItem('user_lang');
  let localeObj = locale === 'en_US' ? en_US : zh_CN;
  return axios(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': data.token || localStorage.getItem('token'),
      "Accept-Language": locale === 'en_US' ? 'en_US' : 'zh_CN'
    },
    data: param
  }).then((res) => {
    res = res.data;
    if (res.errorCode === '600') {
      return message.show('服务异常');
    }
    if (res.errorCode === 'CORE00001') {
      return message.show(localeObj.casesDetail.casesChildren.paymentScheme.sxyebz);
    }
    if ((['300000', '300001'].indexOf(res.errorCode) !== -1 || res.errorCode === ERR_TIME_OUT) && !!data.token) {
      return;
    }
    if (res.errorCode === ERR_TIME_OUT) {
      message.show('登录超时，请重新登录');
      clearUser();
      setTimeout(() => {
        goLogin();
      }, 800);
      return Promise.reject('timeout');
    }
    if (['300000', '300001'].indexOf(res.errorCode) !== -1) {
      message.show('登录超时，请重新登录');
      clearUser();
      setTimeout(() => {
        goLogin();
      }, 800);
      return Promise.reject('timeout');
    }
    if (res.code !== ERR_OK && code === '/core/v1/cure_plan/to_complete_ns') {
      return Promise.reject(res);
    }
    if (res.code !== ERR_OK) {
      if (res.errorCode === 'AIS00070') {
        message.show(res.errorMsg, 3000);
        return Promise.reject(res);
      } else if (res.errorCode === 'AIS00128') {
        message.show(res.errorMsg, 3000);
        return Promise.reject(res);
      }
      if (res.errorInfo) {
        message.show(res.errorInfo.toString(), 2000);
      } else if (res.errorMsg) {
        message.show(res.errorMsg, 2000);
      } else {
        message.show(res.errorMsg || '操作失败', 2000);
      }
      return Promise.reject(res.errorInfo);
    }
    return Promise.resolve(res.data);
  });
}

// function _reloadPageWX() {
//   fetch(805917, {
//     ckey: 'WX_H5_ACCESS_KEY'
//   }).then((data) => {
//     let appId = data.cvalue;
//     let redirectUri = encodeURIComponent(`${location.origin}?${location.hash}`);
//     let url = 'https://open.weixin.qq.com/connect/oauth2/authorize';
//     let suffix = '&response_type=code&scope=snsapi_userinfo#wechat_redirect';
//     setTimeout(() => {
//       location.replace(`${url}?appid=${appId}&redirect_uri=${redirectUri}${suffix}`);
//     }, 100);
//   });
// }

